import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';

import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';


import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MessageComponent } from './components/message/message.component';
import { MenuComponent } from './components/menu/menu.component';
import { RouterModule } from '@angular/router';
import { PopUpComfirmComponent } from './components/pop-up-comfirm/pop-up-comfirm.component';





@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MessageComponent,
    MenuComponent,
    PopUpComfirmComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    ButtonModule,
    MenuModule,
    MessagesModule,
    MessageModule,
    ToolbarModule,
    RouterModule,
    ConfirmDialogModule,
    ToastModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    MessageComponent,
    MenuComponent,
    PopUpComfirmComponent
  ]
})
export class CoreModule { }
