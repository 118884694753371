import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-field-view',
  templateUrl: './field-view.component.html',
  styleUrls: ['./field-view.component.scss']
})
export class FieldViewComponent implements OnInit {

  @Input() label: string;
  @Input() value: string | string[];
  @Input() icon: string;
  @Input() hasIndentPadding: boolean = true;

  values: string[];

  newTel: any;

  ngOnInit() {
    this.values = (this.value instanceof Array) ? this.value : [this.value];
  }

  isOtherLabel(): boolean {
    return this.label !== 'email' && this.label !== 'tel';
  }
}
