import { Component, OnInit } from '@angular/core';

import { TestdbService } from '../shared/testdb.service';

@Component({
  selector: 'app-testdb',
  templateUrl: './testdb.component.html',
  styleUrls: ['./testdb.component.scss'],
})
export class TestdbComponent implements OnInit {
  str: string;

  constructor(private testdbService: TestdbService) {}

  ngOnInit(): void {
    this.testdbService.get().subscribe((str: string) => (this.str = str));
  }
}
