import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {GouvAdresseService} from '@core/services/gouv-adresse.service';

import {Coordonnees} from '@core/classes/coordonnees.class';
import {debounceTime, switchMap, tap} from 'rxjs/operators';

import {FormControl} from '@angular/forms';
import {StateService} from '@core/services/state.service';


@Component({
  selector: 'app-adresse-gouv-field',
  templateUrl: './adresse-gouv-field.component.html',
  styleUrls: ['./adresse-gouv-field.component.scss']
})
export class AdresseGouvFieldComponent implements OnInit {

  @Output() outputCoordonnees = new EventEmitter<Coordonnees>();

  @Input() inputCoordonnes: string = null;

  adressControl = new FormControl();
  adressSelected = false;
  adresses: any;
  isLoading = false;

  get element() {
    if (!this.stateService || !this.stateService.state) {
      return null;
    }
    return this.stateService.state.page;
  }

  constructor(
    private gouvAdresseService: GouvAdresseService,
    private stateService: StateService
  ) {
  }

  ngOnInit() {

    this.adressControl.setValue(this.inputCoordonnes);

    this.adressControl.valueChanges
    .pipe(
      debounceTime(500),
      tap(() => {
        this.adresses = [];
        if (!this.adressSelected) {
          this.isLoading = true;
        }
      }),
      switchMap(
        value =>
          this.gouvAdresseService
          .getAdresse(
            (value) === '' ? '  ' : value
          )
      )
    )
    .subscribe(data => {
      this.isLoading = false;
      if (!this.adressSelected) {
        this.adresses = data;
      }
    });
  }

  isAddressDefined(): boolean {
    return this.adressControl.value !== undefined &&
      this.adressControl.value !== null &&
      this.adressControl.value.length > 0;
  }

  unlock() {
    this.adressSelected = false;
  }

  select(adress: any, event: any) {
    this.adressSelected = true;
    event.target.blur();
    this.adressControl.setValue(adress.label);
    this.adresses = [];
    this.outputCoordonnees.emit(new Coordonnees(adress));
  }

}
