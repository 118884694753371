import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { AuthService } from '@auth/shared/auth.service';
import { ChatService } from '@chat/shared/chat.service';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { environment } from '@environments/environment';
import { Tools } from '@shared/tools';
import { User } from '@user/shared/classes/user.class';
import { from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ServiceworkerService {
  readonly VAPID_PUBLIC_KEY =
    `${environment.vapidPublic}` !== 'undefined'
      ? `${environment.vapidPublic}`
      : 'BMIH13DLV47voh1wDfM6gwBikD64vr4fmrc3bN2BTLfdZ7Onc-2lyY2DCVbNbdSCEsEIbMgxVFcWdwQy3D0eKgY';

  apiSegment = 'notification';

  currentServiceWorker: ServiceWorker;

  user: User;

  constructor(
    private swPush: SwPush,
    private http: HttpClient,
    private authService: AuthService,
    private stateService: StateService,
    private apiService: ApiService,
    private chatService: ChatService,
    private router: Router
  ) {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.ready.then((reg) => {
        navigator.serviceWorker.addEventListener('message', (event) => {
          switch (event.data.type) {
            case 'push':
              this.displayNativeNotification(event.data);
              break;
            case 'activate': {
              if (
                this.currentServiceWorker
                && this.currentServiceWorker
                  !== navigator.serviceWorker.controller
              ) {
                if (
                  confirm(
                    'Nouvelle version disponible, recharger la page maintenant ? Si non des bugs peuvent apparaitre'
                  )
                ) {
                  window.location.reload();
                }
              }
              this.notificationSubscription().subscribe();
              break;
            }
            default:
              Tools.log('message from SW: ', event)();
          }
        });

        this.notificationSubscription().subscribe();
      });
    }
  }

  private notificationSubscription(): Observable<any> {
    return this.authService.isAuth$.pipe(
      mergeMap((isAuth) => {
        if (isAuth && navigator.serviceWorker.controller) {
          return this.stateService.user$;
        }
        Tools.log('unable to use service worker yet')();

        return of(null);
      }),
      mergeMap((user: User) => {
        this.user = user;
        if (this.user && this.swPush.isEnabled) {
          this.currentServiceWorker = navigator.serviceWorker.controller;

          return from(
            this.swPush.requestSubscription({
              serverPublicKey: this.VAPID_PUBLIC_KEY,
            })
          );
        }
        Tools.log('service worker disabled or user not yet ready')();

        return of(null);
      }),
      mergeMap((subscription) => {
        if (subscription && this.user) {
          return this.apiService.post(
            `${this.apiSegment}/subscribe`,
            subscription
          );
        }

        return of(null);
      })
    );
  }

  private displayNativeNotification(notification: any) {
    // Tools.log(notification)();
    const { title } = notification;

    const options = {
      body: notification.body,
      icon: notification.icon,
      timestamp: notification.timestamp,
      lang: notification.lang,
      renotify: notification.renotify,
      tag: notification.tag,
      url: notification.url,
    };

    const n = new Notification(title, options);

    n.addEventListener('click', (e: Event) => {
      n.close();
      const tag = n.tag.split('-');

      if (tag.length > 1) {
        if (tag[0] === 'message') {
          this.chatService.initChatSession(tag[1]).subscribe(() => {
            this.chatService.goToConversation(true);
          });
        }
      } else {
        window.focus();
      }
    });
  }
}
