import { Injectable } from '@angular/core';
// ------------------------------------ SERVICES
import { AbstractEtlService } from '@core/etl/shared/abstract-etl.service';
// ------------------------------------ EXTERNS
import camelcase from 'camelcase';

// ------------------------------------ CLASSES
import { Event } from '../classes/event.class';
// import * as decamelize from 'decamelize';

@Injectable({
  providedIn: 'root',
})
export class EventEtlService implements AbstractEtlService<Event, any, any> {
  modelService?: any;

  context: string;

  data: any;

  model: Event;

  orig: any;

  dest: any;

  provId = 0;

  // protected Constants: PanoramaFormConstants;

  constructor() {}

  init(context: string, data: any, service?: any): void {
    this.context = context;
    this.data = null;
    this.model = null;
    this.orig = null;
    this.dest = null;
    if (service) {
      this.modelService = service;
      this.provId = this.modelService.provId;
    }

    if (!data) {
      return;
    }
    if (typeof data === 'string') {
      this.data = JSON.parse(data);

      return;
    }
    this.data = {
      data: !data.data
        ? null
        : typeof data.data === 'string'
        ? JSON.parse(data.data)
        : data.data,
      model: !data.model
        ? null
        : typeof data.model === 'string'
        ? JSON.parse(data.model)
        : data.model,
    };
  }

  extract(): void {
    this.orig = new Event(this.data.model);
  }

  transform(): void {
    this.model = new Event(this.orig);
  }

  load(): void {
    this.dest = new Event(this.model);
  }

  etl(context: string, data: any, service?: any): void | any {
    this.init(context, data, service);
    if (!this.data) {
      return;
    }
    this.extract();
    if (!this.orig) {
      return;
    }
    this.transform();
    if (!this.model) {
      return;
    }
    this.load();
    if (!this.dest) {
      return;
    }

    return this.dest;
  }

  // ----------------------------------------> PROTECTED

  protected transformData<T = any>(input: any, C: any): T {
    let output: any;

    const data = {};

    if (input) {
      Object.entries(input).forEach((v) => {
        data[camelcase(v[0])] = v[1];
      });
    }
    output = new C(data);

    return output;
  }

  protected transformDataArray(input: any[], T) {
    const output: any[] = [];

    input.forEach((o) => {
      const data = {};

      Object.entries(o).forEach((v) => {
        data[camelcase(v[0])] = v[1];
      });
      output.push(new T(data));
    });

    return output;
  }
}
