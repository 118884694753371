import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MyMessageService {
  private static DETAIL_MAX_LENGTH = 160;

  constructor(public messageService: MessageService) {}

  private static formatDetail(detail: string): string {
    if (detail && detail.length > MyMessageService.DETAIL_MAX_LENGTH) {
      return detail
        .slice(0, MyMessageService.DETAIL_MAX_LENGTH - 3)
        .padEnd(MyMessageService.DETAIL_MAX_LENGTH, '.');
    }

    return detail;
  }

  get messageObserver(): Observable<Message | Message[]> {
    return this.messageService.messageObserver;
  }

  get clearObserver(): Observable<string> {
    return this.messageService.clearObserver;
  }

  add(message: Message): void {
    message.detail = MyMessageService.formatDetail(message.detail);
    this.messageService.add(message);
  }

  addAll(messages: Message[]): void {
    this.messageService.addAll(messages);
  }

  clear(key?: string): void {
    this.messageService.clear(key);
  }
}
