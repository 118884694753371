import { Component, Input, OnInit } from '@angular/core';
import { Contact } from '@contact/shared/classes/contact.class';
import { ContactService } from '@contact/shared/contact.service';
import { DocumentService } from '@core/services/document.service';
import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.scss'],
})
export class UserImageComponent implements OnInit {
  @Input() contact: Contact;

  @Input() isRounded: boolean;

  @Input() selection = false;

  @Input() size = '36px';

  baseUrl = '/user';

  constructor(
    private contactService: ContactService,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    //TODO COMMENTE POUR ENLEVER L'ERREUR BLOB
    //TODO REPARER UPLOAD / DOWNLOAD IMAGE PROFIL CONTACT USER
    // this.loadProfileImage(false);
    // this.contactService.image$.subscribe((contactImageId) => {
    //   if (contactImageId && this.contact.id === contactImageId) {
    //     this.loadProfileImage(true);
    //   }
    // });
  }

  public toggleSelection() {
    this.selection = !this.selection;
  }

  public loadProfileImage(force: boolean) {
    if (this.contact.hasImage || force) {
      this.documentService
        .getContactPicture(this.contact.id)
        .pipe(
          concatMap((file: any) => {
            if (file) {
              return this.documentService.reactiveReadFile(file);
            }
            of(null);
          })
        )
        .subscribe((image) => {
          if (image) {
            this.contact.image = image;
          }
        });
    }
  }

  getType(): string {
    if (this.selection) {
      return 'user-img img-selection';
    }

    return this.contact.id % 2 && this.isRounded
      ? 'user-img initiales-img isRounded'
      : 'user-img initiales-img client';
  }
}
