import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Commentaire } from '@core/classes/commentaire.class';
import { Document } from '@core/classes/document.class';
import { SelectedAndNumber } from '@core/classes/selectedAndNumber.class';
import { DocumentService } from '@core/services/document.service';
import { StateService } from '@core/services/state.service';
import { forkJoin, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ImageHammerConfig } from './imageHammerConfig';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent
  implements AfterViewInit, OnInit, OnChanges, OnDestroy
{
  @Input() images: Document[];

  @Input() enableGalleria = true;

  @ViewChild('galleria') galleria;

  activeIndex = 0;

  displayGallery: boolean;

  imagesSelected: Array<number> = [];

  hammerConfig = new ImageHammerConfig();

  hammer;

  isTouchDevice = 'ontouchstart' in document.documentElement;

  headerMenuClick: Subscription;

  editable = false;

  selectionCount = 0;

  textEdit = false;

  form: FormGroup;

  keys = ['nom', 'commentaire'];

  constructor(
    private documentService: DocumentService,
    private fb: FormBuilder,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      nom: ['', [Validators.required, Validators.min(2)]],
      commentaire: ['', [Validators.required, Validators.min(2)]],
    });
    this.form.valueChanges.subscribe(() => this.isSaveAllowed());
  }

  ngAfterViewInit() {
    const element = document.getElementById('picture-list');

    if (this.isTouchDevice) {
      this.hammer = this.hammerConfig.buildHammer(element);

      this.hammer.on('press', (ev: any) => {
        ev.preventDefault();
        if (!this.editable) {
          this.optionMenu(Number(ev.target.id.split('-')[2]));
        }
      });
    }

    this.headerMenuClick = this.stateService.headerMenuClick$.subscribe(
      (click) => {
        if (click && click.title === 'ModifierMod') {
          if (this.editable) {
            this.clearSelectedImages();
          } else {
            this.setEditable(true);
          }
        } else if (click && click.title === 'check') {
          this.clearSelectedImages();
        }
      }
    );
  }

  ngOnDestroy() {
    this.editable = false;
    this.stateService.setEditMode(this.editable);
    this.stateService.selectedAndNumber(
      new SelectedAndNumber(this.editable, 0)
    );
    this.headerMenuClick.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.stateService.selectedAndNumber(
      new SelectedAndNumber(
        this.editable,
        this.editable ? this.imagesSelected.length : this.images.length
      )
    );
  }

  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (!this.textEdit) {
      if (event.key === 'ArrowRight') {
        this.nextImage(this.activeIndex + 1);
      } else if (event.key === 'ArrowDown') {
        this.nextImage(this.activeIndex - 1);
      } else if (event.key === 'ArrowLeft') {
        this.nextImage(this.activeIndex - 1);
      } else if (event.key === 'ArrowUp') {
        this.nextImage(this.activeIndex + 1);
      } else if (event.key === 'Escape') {
        this.hideGallery();
      }
    }
  }

  nextImage(newValue: number) {
    if (!this.textEdit) {
      this.cancelEdit();
      if (this.images && newValue >= 0 && newValue <= this.images.length - 1) {
        this.activeIndex = newValue;
      } else if (newValue < 0) {
        this.activeIndex = this.images.length - 1;
      } else if (newValue >= this.images.length) {
        this.activeIndex = 0;
      }
    }
  }

  optionMenu(fileId: number) {
    if (this.imagesSelected.includes(fileId)) {
      this.imagesSelected.splice(
        this.imagesSelected.findIndex((i) => i === fileId),
        1
      );
    } else {
      this.imagesSelected.push(fileId);
      this.setEditable(true);
    }
    document
      .getElementById(`elem-image-${fileId}`)
      .classList.toggle('selected');
    if (this.imagesSelected.length > 0) {
      this.setEditable(true);
    } else {
      this.setEditable(false);
    }
  }

  showGallery(image: Document, index, event: any) {
    if (
      event.ctrlKey
      || event.metaKey
      || this.editable
      || !this.enableGalleria
    ) {
      this.optionMenu(image.erpId);
    } else {
      this.activeIndex = index;
      this.displayGallery = true;
    }
  }

  hideGallery() {
    this.cancelEdit();
    this.displayGallery = false;
  }

  setEditable(editable: boolean) {
    if (editable) {
      document.getElementById('picture-list').classList.add('editable');
      this.editable = true;
    } else {
      document.getElementById('picture-list').classList.remove('editable');
      this.editable = false;
    }
    this.selectionCount = this.imagesSelected.length;
    this.stateService.selectedAndNumber(
      new SelectedAndNumber(
        this.editable,
        this.editable ? this.imagesSelected.length : this.images.length
      )
    );
    this.stateService.setEditMode(this.editable);
  }

  clearSelectedImages() {
    this.imagesSelected.map((id) =>
      document.getElementById(`elem-image-${id}`).classList.toggle('selected')
    );
    document.getElementById('picture-list').classList.remove('editable');
    this.imagesSelected.length = 0;
    this.editable = false;
    this.stateService.setEditMode(this.editable);
    this.stateService.selectedAndNumber(
      new SelectedAndNumber(this.editable, this.images.length)
    );
  }

  deleteDocument(docId: number) {
    return this.documentService.delete(docId).pipe(
      map((fileId: number) => {
        this.images = this.images.filter((i) => i.erpId !== fileId);
        this.stateService.selectedAndNumber(
          new SelectedAndNumber(
            this.editable,
            this.editable ? this.imagesSelected.length : this.images.length
          )
        );
      })
    );
  }

  deleteOneDocument(doc: Document) {
    return this.deleteDocument(doc.erpId).subscribe(() => {
      this.displayGallery = false;
    });
  }

  deleteDocuments() {
    const subscribes = this.imagesSelected.map((fileId) =>
      this.deleteDocument(fileId)
    );

    forkJoin(subscribes).subscribe(() => {
      this.imagesSelected.splice(0, this.imagesSelected.length);
      this.displayGallery = false;
      this.setEditable(false);
    });
  }

  downloadDocument(doc: Document) {
    return this.documentService.get(doc.erpId).subscribe((file) => {
      if (doc.extension === 'pdf') {
        this.documentService.openToAnotherWindow(file);
      } else {
        this.documentService.saveToDisk(file, doc.label);
      }
    });
  }

  validEditDocument(doc: Document) {
    if (this.isSaveAllowed()) {
      doc.changeName(`${this.form.value.nom}.${doc.extension}`);
      doc.commentaire = new Commentaire();
      doc.commentaire.commentaire = this.form.value.commentaire;
      this.documentService.update(doc, doc.file).subscribe(() => {
        this.hideGallery();
      });
    }
  }

  cancelEdit() {
    if (this.textEdit) {
      this.form.reset();
      this.toggleEditDocument();
    }
  }

  private isSaveAllowed() {
    return this.form.status === 'VALID' && !this.form.pristine;
  }

  toggleEditDocument(doc?: Document) {
    const inputs = document.getElementsByClassName('document-edit-input');

    const inputLength = inputs.length;

    this.textEdit = !this.textEdit;

    if (this.textEdit) {
      this.galleria.showItemNavigators = false;
      this.galleria.showItemNavigatorsOnHover = false;
      if (doc) {
        this.form.patchValue({
          nom: doc.baseFilename,
          commentaire: doc.commentaire ? doc.commentaire.commentaire : null,
        });
      }
      document.getElementById('image-gallery').classList.add('image-text-edit');
      document
        .getElementById('edit-picture')
        .classList.add('edit-button-hidden');
      document
        .getElementById('edit-buttons')
        .classList.remove('edit-button-hidden');
      document
        .getElementById('form-edit-document')
        .classList.remove('form-edit-hidden');
    } else {
      this.galleria.showItemNavigators = true;
      this.galleria.showItemNavigatorsOnHover = true;
      document
        .getElementById('image-gallery')
        .classList.remove('image-text-edit');
      document
        .getElementById('edit-picture')
        .classList.remove('edit-button-hidden');
      document
        .getElementById('edit-buttons')
        .classList.add('edit-button-hidden');
      document
        .getElementById('form-edit-document')
        .classList.add('form-edit-hidden');
    }

    for (let i = 0; i < inputLength; i++) {
      if (this.textEdit) {
        inputs[i].classList.remove('document-edit-input-hidden');
      } else {
        inputs[i].classList.add('document-edit-input-hidden');
      }
    }
  }
}
