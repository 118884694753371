import { Base } from './base.class';
import { Commentaire } from './commentaire.class';

export class Document extends Base {

  public static frontToBackProps = Object.assign({}, Base.frontToBackProps, {
    erpId: 'erp_id',
    dossierId: 'dossier_id',
    typeElementId: 'type_element_id',
    elementId: 'element_id',
    CommentaireId: 'commentaire_id',
    createdAt: 'created_at',
    updatedAt: 'updated_at'
  });

  public static backToFrontProps = Object.assign({}, Base.backToFrontProps, {
    erp_id: 'erpId',
    dossier_id: 'dossierId',
    type_element_id: 'typeElementId',
    element_id: 'elementId',
    commentaire_id: 'CommentaireId',
    created_at: 'createdAt',
    updatedAt: 'updatedAt'
  });

  erpId: number;
  label: string;
  type: number;
  commentaireId: number;
  commentaire: Commentaire;
  dossierId: number;
  typeElementId: number;
  elementId: number;
  extension: string;
  content: string | ArrayBuffer;
  file: File;
  baseFilename: string;
  createdAt?: Date;
  updatedAt?: Date;

  constructor(options?: {
    id?: number,
    provId?: number,
    erpId?: number,
    label?: string,
    type?: number,
    commentaire?: Commentaire,
    commentaire_id?: number,
    dossierId?: number,
    typeElementId?: number,
    elementId?: number,
    file?: File,
    baseFilename?: string,
    createdAt?: Date | string,
    updatedAt?: Date | string,

  }) {
    Object.keys(Document.frontToBackProps).forEach((key: string) => {
      if (options[key] === undefined && options[Document.frontToBackProps[key]] !== undefined) {
        options[key] = options[Document.frontToBackProps[key]];
      }
    });
    super(options);
    this.initFromArray(options, ['erpId', 'label', 'type', 'elementId', 'commentaire', 'dossierId', 'typeElementId', 'updatedAt', 'createdAt']);

    this.createdAt = new Date(this.createdAt);
    this.updatedAt = new Date(this.updatedAt);

    if (options.type) {
      this.setInnerType(options);
    }
    if (this.label) {
      this.baseFilename = this.getFilename(this.label);
      this.extension = this.getExtension(this.label);
    }
  }

  public changeName(label: string) {
    if (label) {
      this.label = label;
      this.baseFilename = this.getFilename(this.label);
      this.extension = this.getExtension(this.label);
    }
  }

  private setInnerType(options) {
    if (options.type.id) {
      this.type = options.type.id;
    }
  }

  private getExtension(path) {
    const basename = path.split(/[\\/]/).pop();
    const pos = basename.lastIndexOf('.');

    if (basename === '' || pos < 1) {
        return '';
    }
    return basename.slice(pos + 1);
  }

  private getFilename(path: string) {
    const basename = path.split(/[\\/]/).pop();
    const pos = basename.lastIndexOf('.');

    if (basename === '' || pos < 1) {
        return path;
    }
    return basename.slice(0, pos);
  }
}
