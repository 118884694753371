import { Component, Input, OnInit } from '@angular/core';
import { User } from '@user/shared/classes/user.class';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss'],
})
export class ProfilComponent implements OnInit {
  @Input() user: User;

  constructor() {}

  ngOnInit(): void {}
}
