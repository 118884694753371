import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/shared/auth.service';
import { MyMessageService } from '@core/services/my-message.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { StateService } from '../services/state.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private stateService: StateService,
    private myMessageService: MyMessageService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handle401(error);
        this.handleMissingTokenParameter(error);
        this.handleMalformedJwtToken(error);
        this.handle404(error);

        return this.handleError(error);
      })
    );
  }

  handleError(error) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Status: ${error.status}\nMessage: ${error.error}`;
    }
    this.stateService.isLoading$.next(false);
    this.stateService.isHttpError$.next(error.error);
    this.myMessageService.clear();
    this.myMessageService.add({
      severity: 'warn',
      summary: 'Erreur : ',
      detail: error.error,
    });
    console.error(errorMessage);

    return throwError(() => errorMessage);
  }

  handleMissingTokenParameter(error): void {
    if (error.error === 'missing parameter in token') {
      this.authService
        .signout()
        .subscribe(() => this.router.navigate(['/auth']));
    }
  }

  handleMalformedJwtToken(error): void {
    if (error.error === 'jwt malformed') {
      this.authService
        .signout()
        .subscribe(() => this.router.navigate(['/auth']));
    }
  }

  handle401(error) {
    if (error instanceof HttpErrorResponse && error.status === 401) {
      if (error.error === 'invalid token') {
        throw error;
      }
    }
  }

  handle404(error) {
    if (error instanceof HttpErrorResponse && error.status === 404) {
      this.router.navigate(['/404']);
    }
  }

  handle406(error) {
    if (error instanceof HttpErrorResponse && error.status === 406) {
      this.stateService.isLoading$.next(false);
      this.stateService.isHttpError$.next(error.error);
      throw error;
    }
  }
}
