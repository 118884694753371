import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwPush } from '@angular/service-worker';
import { CoreModule } from '@core/core.module';
import { ErrorInterceptor } from '@core/interceptors/error.interceptor';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { MyMessageService } from '@core/services/my-message.service';
import { environment } from '@environments/environment';
import { Angulartics2Module } from 'angulartics2';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MyHammerConfig } from './my-hammer.config';
import { ServiceworkerService } from './offline/serviceworker.service';
import { SharedModule } from './shared/shared.module';
import { TestdbModule } from './testdb/testdb.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: new URL(window.location.href).hostname,
  },
  palette: {
    popup: {
      background: '#172425',
    },
    button: {
      background: '#FFBD30',
    },
  },
  theme: 'classic',
  position: 'bottom-right',
  type: 'opt-in',
  content: {
    message:
      'Ce site Web utilise des cookies pour vous garantir la meilleure expérience sur notre site.',
    dismiss: 'Accepter',
    deny: 'Refuser',
    link: 'En savoir plus.',
    href: '/legal',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    AppRoutingModule,
    CoreModule,
    TestdbModule,
    HammerModule,
    SharedModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    Angulartics2Module.forRoot(),
    ServiceWorkerModule.register('../service-worker.js', {
      enabled: environment.completeProduction,
    }),
    FontAwesomeModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    SwPush,
    ServiceworkerService,
    MessageService,
    ConfirmationService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    MyMessageService,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
