import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IHeritageItem } from '@panorama/shared/interfaces/i-heritage-item';

@Component({
  selector: 'app-liste-menu-items',
  templateUrl: './liste-menu-items.component.html',
  styleUrls: ['./liste-menu-items.component.scss'],
})
export class ListeMenuItemsComponent {
  @Input() style = 'default';

  @Input() header = true;

  @Input() isSpecialAction: boolean;

  @Input() items: Array<IHeritageItem> = [];

  @Output() selectItem = new EventEmitter<Partial<IHeritageItem>>(null);

  @Output() specialAction = new EventEmitter<void>(null);

  constructor() {}

  onSelectItem(item: IHeritageItem): void {
    if (!item.disabled) {
      this.selectItem.emit(item);
    }
  }
}
