import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import * as localeFr from '@localeFr';

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
})
export class DateFieldComponent {
  date: Date;

  @Input() required: boolean;

  @Input()
  set inputDate(date: Date) {
    if (date !== this.date) {
      this.date = date;
    }
  }

  @Output() outputDate = new EventEmitter<Date>();

  fr = localeFr['default'];

  @ViewChild('calendar')
  calendar: any;

  constructor() {}

  select(date: Date) {
    this.outputDate.emit(date);
  }
}
