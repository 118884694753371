import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@core/services/state.service';
import { Message } from 'primeng/api';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit, OnDestroy {
  msgs: Message[] = [];

  displayMessageService = false;

  isUpper = false;

  private onDestroy: Subject<void> = new Subject<void>();

  constructor(private stateService: StateService) {}

  ngOnInit(): void {
    this.stateService.isPopupMessageUpper$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((isPopupMessageUpper: boolean) => {
        this.isUpper = isPopupMessageUpper;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  styleClass(): string {
    if (this.isUpper) {
      return 'upper';
    }

    return 'lower';
  }
}
