import { Component, Input, OnInit } from '@angular/core';
import { Contact } from '@contact/shared/classes/contact.class';
import { ArtizLabelPipe } from '@shared/pipes/artiz-label.pipe';

@Component({
  selector: 'app-contact-field',
  templateUrl: './contact-field.component.html',
  styleUrls: ['./contact-field.component.scss'],
  providers: [ArtizLabelPipe],
})
export class ContactFieldComponent implements OnInit {
  @Input() type: string;

  @Input() context: string;

  @Input() contact: Contact;

  visibleSidebar = false;

  title: string;

  actionLabel: string;

  constructor(private artizLabel: ArtizLabelPipe) {}

  ngOnInit(): void {
    this.title =
      this.type === 'client'
        ? this.artizLabel.transform('CLIENT_LABEL')
        : this.artizLabel.transform('ARTISAN_TITRE');
    this.actionLabel =
      this.context === 'add'
        ? this.artizLabel.transform('ADD_LABEL')
        : this.artizLabel.transform('EDIT_LABEL');
  }

  toggleVisible() {
    this.visibleSidebar = !this.visibleSidebar;
  }
}
