import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
} from '@angular/forms';
import { Document } from '@core/classes/document.class';
import { SelectedAndNumber } from '@core/classes/selectedAndNumber.class';
import { DocumentService } from '@core/services/document.service';
import { StateService } from '@core/services/state.service';
import { forkJoin, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent
  implements AfterViewInit, OnInit, OnChanges, OnDestroy
{
  @Input() documents: Document[];

  @Input() enableEdit = true;

  selection = false;

  headerMenuClick: Subscription;

  documentsSelected: number[] = new Array<number>();

  constructor(
    private documentService: DocumentService,
    private fb: FormBuilder,
    private stateService: StateService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.headerMenuClick = this.stateService.headerMenuClick$.subscribe(
      (click) => {
        if (click && click.title === 'ModifierMod') {
          if (this.selection) {
            this.clearSelection();
          } else {
            this.selection = !this.selection;
          }
        } else if (click && click.title === 'check') {
          this.clearSelection();
        }
      }
    );
  }

  ngOnDestroy() {
    this.stateService.selectedAndNumber(
      new SelectedAndNumber(this.selection, 0)
    );
    this.headerMenuClick.unsubscribe();
  }

  getFormatedDate(date: Date) {
    let str = '';

    const month = date.toLocaleString('fr', { month: 'long' });

    str = str.concat(`${date.getDate().toString()} `);
    str = str.concat(`${month.substring(0, 3)} `);
    str = str.concat(date.getFullYear().toString());

    return str;
  }

  selectElement(doc: Document) {
    if (!this.getSelectedDocumentFromId(doc.erpId)) {
      this.documentsSelected.push(doc.erpId);
    } else {
      this.documentsSelected.splice(
        this.documentsSelected.indexOf(doc.erpId),
        1
      );
    }
    if (this.documentsSelected.length !== 0) {
      this.selection = true;
      this.stateService.selectedAndNumber(
        new SelectedAndNumber(this.selection, this.documentsSelected.length)
      );
      this.stateService.setEditMode(this.selection);
    } else {
      this.clearSelection();
    }
  }

  clearSelection() {
    const selectedElement = Object.assign([], this.documentsSelected);

    selectedElement.forEach((doc: number) => {
      const selection = this.getSelectedDocumentFromId(doc);

      this.selectElement(selection);
    });
    this.selection = false;
    this.stateService.selectedAndNumber(
      new SelectedAndNumber(this.selection, this.documents.length)
    );
    this.stateService.setEditMode(this.selection);
  }

  getSelectedDocumentFromId(docId: number): Document {
    return this.documents.find((doc: Document) => {
      if (doc.erpId === docId && this.documentsSelected.includes(docId)) {
        return doc;
      }
    });
  }

  deleteDocuments() {
    const subscribes = this.documentsSelected.map((fileId) =>
      this.deleteDocument(fileId)
    );

    forkJoin(subscribes).subscribe(() => {});
  }

  deleteDocument(docId: number) {
    return this.documentService.delete(docId).pipe(
      map((fileId: number) => {
        this.documentsSelected.filter((i) => i !== fileId);
        this.clearSelection();
        this.documents = this.documents.filter((i) => i.erpId !== fileId);
      })
    );
  }

  deleteOneDocument(doc: Document) {
    return this.deleteDocument(doc.erpId).subscribe(() => {});
  }

  downloadDocument(doc: Document) {
    return this.documentService.get(doc.erpId).subscribe((file) => {
      if (doc.extension === 'pdf') {
        this.documentService.openToAnotherWindow(file);
      } else {
        this.documentService.saveToDisk(file, doc.label);
      }
      this.clearSelection();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.stateService.selectedAndNumber(
      new SelectedAndNumber(
        this.selection,
        this.selection ? this.documentsSelected.length : this.documents.length
      )
    );
  }
}
