import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Contact } from '@contact/shared/classes/contact.class';
import { StateService } from '@core/services/state.service';
import { DossierService } from '@dossier/shared/dossier.service';
import { UserImageComponent } from '@shared/components/user-image/user-image.component';

@Component({
  selector: 'app-contact-item',
  templateUrl: './contact-item.component.html',
  styleUrls: ['./contact-item.component.scss'],
})
export class ContactItemComponent implements OnInit, AfterViewInit {
  @Input() item: Contact;

  @Input() selection = false;

  @Input() preSelected = false;

  @Output() selected = new EventEmitter<any>();

  @ViewChild('appuserimage') userImage: UserImageComponent;

  constructor(
    private router: Router,
    private dossierService: DossierService,
    private stateService: StateService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.preSelected) {
      this.toggleSelection();
    }
  }

  public toggleSelection() {
    this.userImage.toggleSelection();
  }

  itemClick() {
    const { forms } = this.stateService;

    let segments: string[];

    if (this.stateService.state.element === 'chat') {
      this.selected.emit(this.item);
    } else {
      if (
        forms
        && forms.length
        && forms[forms.length - 1].element === 'dossier'
      ) {
        this.dossierService.current.setClient(this.item);
        segments = this.stateService.getFormSegments(forms[forms.length - 1]);
      } else {
        segments = this.stateService.urlSegments.slice();
        segments.push(this.item.id.toString());
      }
      this.router.navigate(segments);
    }
  }
}
