import { Injectable } from '@angular/core';
import { Dossier } from '@dossier/shared/classes/dossier.class';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Panorama } from '@panorama/shared/classes/panorama.class';
import { Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { FlowType } from './flowType.enum';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  storedFichier = new Array<number>();

  constructor(private localStorage: LocalStorage) {}

  // Dossier
  addAllDossierLocalStorage(dossiers: Dossier[]): Observable<boolean> {
    return this.localStorage.setItem('dossiers', dossiers);
  }

  getAllDossierLocalStorage(): Observable<Dossier[]> {
    return this.localStorage
      .getItem<Dossier[]>('dossiers')
      .pipe(map((dossiers: Dossier[]) => (!dossiers ? [] : dossiers)));
  }

  getOneDossierLocalStorage(id: number): Observable<Dossier> {
    return this.localStorage
      .getItem<Dossier[]>('dossiers')
      .pipe(map((dossiers: Dossier[]) => dossiers.find((d) => d.id === id)));
  }

  deleteDossierLocalStorage(id: number): Observable<Dossier> {
    return this.localStorage.getItem('dossiers').pipe(
      concatMap((ds: Dossier[]) => {
        if (ds && ds.length) {
          const dossier = ds.find((d) => d.id === id);

          const index = ds.findIndex((d) => d.id === id);

          if (index !== -1) {
            ds.splice(index, 1);
            if (ds.length) {
              return this.localStorage
                .setItem('dossiers', ds)
                .pipe(concatMap(() => of(dossier)));
            }

            return this.localStorage
              .removeItem('dossiers')
              .pipe(concatMap(() => of(dossier)));
          }
        }

        return of(null);
      })
    );
  }

  updateDossierLocalStorage(
    dossier: Dossier,
    data: any,
    dossierId: number,
    updateType: FlowType,
    forceSave: boolean
  ): Observable<boolean> {
    return this.getAllDossierLocalStorage().pipe(
      concatMap((dossiers: Dossier[]) => {
        const orgDossier =
          dossiers.length > 0
            ? dossiers.find((d) => d.id === dossier.id)
            : null;

        if (updateType === FlowType.BROWSING) {
          if (dossiers === null) {
            // dossiers entry does not exist
            dossiers = [];
          } else if (orgDossier) {
            // dossier exists
            // Tools.log('replace Dossier' + dossierId)();
            return this.replaceDossierLocalStorage(dossiers, dossier);
          }
          dossiers.push(dossier);
          if (forceSave) {
            // Tools.log('set Dossier' + dossierId)();
            return this.localStorage.setItem('dossiers', dossiers);
          }

          return of(false);
        }
        if (updateType === FlowType.BDDUPDATEFORM && orgDossier) {
          data.forEach((formName: string) => {
            orgDossier[formName] = formName;
          });

          return this.replaceDossierLocalStorage(dossiers, orgDossier);
        }
        if (updateType === FlowType.BDDUPDATEPENDING && orgDossier) {
          return this.replaceDossierLocalStorage(dossiers, dossier);
        }
        if (updateType === FlowType.BDDUPDATENOM && orgDossier) {
          return this.getOneDossierLocalStorage(dossierId).pipe(
            concatMap((prevDossier) => {
              if (prevDossier) {
                // prevDossier.nom = data.nom;
                return this.replaceDossierLocalStorage(dossiers, prevDossier);
              }

              return of(null);
            })
          );
        }

        return of(null);
      })
    );
  }

  replaceDossierLocalStorage(dossiers: Dossier[], dossier: Dossier) {
    const index = dossiers.map((d) => d.id).indexOf(dossier.id);

    dossiers[index] = dossier;

    return this.localStorage.setItem('dossiers', dossiers);
  }

  /** * panorama ** */
  getOnePanoramaLocalStorage(id: number): Observable<Panorama> {
    return this.localStorage.getItem<Panorama[]>('panoramas').pipe(
      map((panoramas: Panorama[]) => {
        if (!panoramas) {
          panoramas = [];
        }

        return panoramas.find((d) => d.id === id);
      })
    );
  }

  updatePanoramaLocalStorage(panorama: Panorama): Observable<any> {
    return this.localStorage.getItem<Panorama[]>('panoramas').pipe(
      concatMap((panoramas: Panorama[]) => {
        if (!panoramas) {
          panoramas = [];
        }
        const index = panoramas.findIndex((d) => d.id === panorama.id);

        if (index === -1) {
          panoramas.push(panorama);
        } else {
          panoramas.splice(index, 1, panorama);
        }

        return this.localStorage.setItem('panoramas', panoramas);
      })
    );
  }

  removePanoramaLocalStorage(panorama: Panorama): Observable<any> {
    return this.localStorage.getItem<Panorama[]>('panoramas').pipe(
      concatMap((panoramas: Panorama[]) => {
        if (!panoramas) {
          panoramas = [];
        }
        const index = panoramas.findIndex((d) => d.id === panorama.id);

        if (index === -1) {
          panoramas.splice(index, 1, panorama);
        }

        return this.localStorage.setItem('panoramas', panoramas);
      })
    );
  }

  /** * login *** */
  setLocalToken(token: string): Observable<any> {
    return this.localStorage.setItem('token', token);
  }

  getLocalToken(): Observable<string> {
    return this.localStorage
      .getItem<string>('token')
      .pipe(map((token: string) => token));
  }

  emptyLocalStorage(): Observable<any> {
    return this.localStorage.clear();
  }
}
