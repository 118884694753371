export class SelectedAndNumber {
  selected: boolean;
  count: number;

  constructor(
    selected: boolean,
    count: number
  ) {
    this.selected = selected;
    this.count = count;
  }
}
