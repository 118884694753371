import { AgendaService } from '@agenda/shared/agenda.service';
import { Injectable } from '@angular/core';
import { Contact } from '@contact/shared/classes/contact.class';
import { ContactService } from '@contact/shared/contact.service';
import { DossierService } from '@dossier/shared/dossier.service';
import { User } from '@user/shared/classes/user.class';
import { UserService } from '@user/shared/user.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { State } from '../classes/state.class';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class RightsService {
  state: State = null;

  user: User = null;

  contact: Contact;

  constructor(
    private stateService: StateService,
    private userService: UserService,
    private agendaService: AgendaService,
    private contactService: ContactService,
    private dossierService: DossierService
  ) {}

  isAllowed(action: string): Observable<boolean> {
    this.state = this.stateService.state;
    this.user = this.userService.user;
    this.contact = !this.user ? null : this.user.contact;

    if (!this.state || !this.contact) {
      if (!this.contact) {
        console.error('not auth user, allow buttons');

        return of(true);
      }

      return of(false);
    }

    if (action === 'search') {
      return of(!!this.user);
    }

    if (action === 'add') {
      return of(this.user.roles.includes('artisan'));
    }

    if (action === 'edit') {
      if (!this.user.roles.includes('artisan') && !this.isChefGroup()) {
        return of(false);
      }
      if (this.state.element === 'dossier') {
        const dossier = this.dossierService.current;

        if (!dossier) {
          return of(false);
        }

        return of(this.isChefDossier(dossier));
      }
      if (this.state.element === 'contact') {
        const contact = this.contactService.current;

        if (!contact) {
          return of(false);
        }

        return this.isChefGroup()
          ? of(true)
          : this.userService.hasRight('edit', 'contact', contact.id);
      }
      if (this.state.element === 'user') {
        return of(true);
      }
      if (this.state.element === 'agenda') {
        return of(this.agendaService.current.editable);
      }
    }

    if (action === 'delete') {
      if (!this.user.roles.includes('artisan') && !this.isChefGroup()) {
        return of(false);
      }
      if (this.state.element === 'dossier') {
        const dossier = this.dossierService.current;

        if (!dossier) {
          return of(false);
        }

        return of(this.isChefDossier(dossier));
      }
      if (this.state.element === 'contact') {
        const contact = this.contactService.current;

        if (!contact) {
          return of(false);
        }

        return this.isChefGroup()
          ? of(true)
          : this.userService.hasRight('delete', 'contact', contact.id);
      }
    }

    if (action === 'editMode') {
      const dossier = this.dossierService.current;

      if (!dossier) {
        return of(false);
      }

      return of(this.isChefDossier(dossier));
    }

    if (action === 'upload') {
      const dossier = this.dossierService.current;

      if (!dossier) {
        return of(false);
      }

      return of(this.isChefDossier(dossier));
    }

    if (action === 'deleteSelection') {
      const dossier = this.dossierService.current;

      if (!dossier) {
        return of(false);
      }

      return of(this.isChefDossier(dossier));
    }

    if (action === 'check') {
      if (this.state.element === 'gallery') {
        const dossier = this.dossierService.current;

        if (!dossier) {
          return of(false);
        }

        return of(this.isChefDossier(dossier));
      }
    }

    return of(true);
  }

  public isRouteAllowed(url: string): Observable<boolean> {
    this.user = this.userService.user;
    this.contact = !this.user ? null : this.user.contact;

    if (!this.contact) {
      return of(false);
    }

    // TODO: correct right for adminartiz
    if (this.user.isAdmin() || this.user.isSuperAdmin()) {
      return of(true);
    }

    const splitUrl = url.split('/');

    if (splitUrl[1] === 'dossier') {
      if (splitUrl[3] === 'edit') {
        const dossier = this.dossierService.current;

        if (!dossier) {
          return this.dossierService
            .get(Number(splitUrl[2]), {
              scope: 'one',
            })
            .pipe(map((dos) => (dos ? this.isChefDossier(dos) : false)));
        }

        return of(this.isChefDossier(dossier));
      }
    }

    return of(true);
  }

  public isChefGroup() {
    if (this.user.isAdmin() || this.user.isSuperAdmin()) {
      return true;
    }
  }

  private isChefDossier(dossier) {
    if (this.isChefGroup()) {
      return true;
    }

    return dossier.chefProjet.id === this.contact.id;
  }

  private isArtisanDossier(dossier) {
    return dossier.artisans.find((a) => a.id === this.contact.id);
  }

  private isClientDossier(dossier) {
    return dossier.client.id === this.contact.id;
  }
}
