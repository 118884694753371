import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ReferenceService } from '@core/services/reference.service';
import { StateService } from '@core/services/state.service';
import { Tools } from '@shared/tools';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
// @TODO Angulartics
// import { Angulartics2Piwik } from 'angulartics2/piwik';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentChecked, OnDestroy, OnInit {
  routerSubscription: Subscription;

  isLoadingSubscription: Subscription;

  isHttpErrorSubscription: Subscription;

  isAuthSubscription: Subscription;

  isMenuActiveSubscription: Subscription;

  statusChangeSubscription: Subscription;

  isLoading = false;

  isMenuActive = false;

  isHttpError = null;

  isAuth = true;

  isChecked = false;

  page: string;

  displayMessageService = true;

  baseUrl = new URL(window.location.href).origin;

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private stateService: StateService,
    // private serviceworkerService: ServiceworkerService,
    private referenceService: ReferenceService,
    private ccService: NgcCookieConsentService // @TODO Angulartics // private angulartics2Piwik: Angulartics2Piwik
  ) {}

  ngOnInit(): void {
    this.sendAnalytics();

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      () => {
        this.sendAnalytics();
      }
    );

    this.referenceService.init();
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.stateService.setState(event.url);
        this.stateService.setMenu(false);
        this.page = this.stateService.state.page;
      }
    });
    this.isMenuActiveSubscription = this.stateService.isMenuActive$.subscribe(
      (res: boolean) => {
        this.isMenuActive = res;
        if (this.isMenuActive) {
          this.stateService.editHeader([true], ['hideBottomItem']);
        } else {
          this.stateService.editHeader([false], ['hideBottomItem']);
        }
      }
    );
    this.isLoadingSubscription = this.stateService.isLoading$.subscribe(
      (res: boolean) => {
        this.isLoading = res;
      }
    );
    this.isHttpErrorSubscription = this.stateService.isHttpError$.subscribe(
      (res) => {
        this.isHttpError = res;
      }
    );
    this.isAuthSubscription = this.stateService.isAuth$.subscribe(
      (isStateAuth: boolean) => {
        if (
          !isStateAuth &&
          this.stateService.urlSegments &&
          this.stateService.urlSegments.length > 1 &&
          this.stateService.urlSegments[1] !== 'auth'
        ) {
          this.router.navigate(['/auth']);
        }
      }
    );
  }

  sendAnalytics(): void {
    if (this.ccService.hasConsented() && this.ccService.hasAnswered()) {
      if (this.baseUrl === 'https://monartiz.com') {
        // @TODO Angulartics
        // this.angulartics2Piwik.startTracking();
      } else {
        Tools.log('unable to send analytics')();
      }
    } else {
      // Tools.log('No cookie')();
    }
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.isLoadingSubscription.unsubscribe();
    this.isHttpErrorSubscription.unsubscribe();
    if (this.isAuthSubscription) {
      this.isAuthSubscription.unsubscribe();
    }
  }

  showMenu(): void {
    this.stateService.setMenu(true);
  }

  /**
   * Methode using primeng (swipeleft) event to close menu
   */
  swipeLeftToCloseMenu(): void {
    if (this.isMenuActive) {
      this.stateService.setMenu(false);
    }
  }
}
