import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContactService } from '@contact/shared/contact.service';
import { State } from '@core/classes/state.class';
import { StateService } from '@core/services/state.service';
import { DossierService } from '@dossier/shared/dossier.service';
import { TacheService } from '@tache/shared/tache.service';
import { User } from '@user/shared/classes/user.class';
import { UserService } from '@user/shared/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-items',
  templateUrl: './search-items.component.html',
  styleUrls: ['./search-items.component.scss'],
})
export class SearchItemsComponent implements OnDestroy, OnInit {
  searchFilterSubscription: Subscription;

  userSubscription: Subscription;

  stateSubscription: Subscription;

  searchFilter: string = null;

  service: any = null;

  user: User = null;

  element: string = null;

  elementName: string = null;

  liste: any[] = [];

  items: any[] = [];

  selection: Array<any>;

  constructor(
    private stateService: StateService,
    private contactService: ContactService,
    private dossierService: DossierService,
    private tacheService: TacheService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.userService.user$.subscribe((user: User) => {
      if (user) {
        this.user = user;
      }
      if (this.user && this.element) {
        this.initSearch();
      }
    });
    this.stateSubscription = this.stateService.state$.subscribe(
      (state: State) => {
        if (state) {
          this.element = state.element;
        }
        if (this.user && this.element) {
          this.initSearch();
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.searchFilterSubscription) {
      this.searchFilterSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  private initSearch() {
    this.elementName = this.stateService.getElementName();
    this.setService();
    this.service
      .list({
        user: this.user,
        filter: '',
      })
      .subscribe((items: any[]) => {
        this.liste = items;
        this.searchFilterSubscription =
          this.stateService.searchFilter$.subscribe((value: string) => {
            if (!value || !value.length) {
              this.searchFilter = null;
              this.items = [];
              this.selection = [];
              if (this.stateService.state.element === 'chat') {
                // force display liste
                this.items = this.liste;
              }

              return;
            }
            this.searchFilter = value;
            this.items = this.liste.filter(
              (d) =>
                d.search
                && this.sansAccent(d.search)
                  .toUpperCase()
                  .indexOf(this.sansAccent(this.searchFilter.toUpperCase()))
                  !== -1
            );
          });
      });
  }

  private setService(): void {
    if (!this.stateService.state) {
      this.service = null;

      return;
    }
    switch (this.stateService.state.element) {
      case 'chat':
      case 'contact':
        this.service = this.contactService;
        break;
      case 'dossier':
        this.service = this.dossierService;
        break;
      case 'tache':
        this.service = this.tacheService;
        break;
    }
  }

  selected(value: any) {
    if (this.selection.indexOf(value) === -1) {
      this.selection.push(value);
    }
  }

  unselect(value: any) {
    this.selection = this.selection.filter((e) => value !== e);
    if (this.searchFilterSubscription) {
      this.searchFilterSubscription.unsubscribe();
    }
  }

  private sansAccent(str: string): string {
    const accent = [
      /[\300-\306]/g,
      /[\340-\346]/g, // A, a
      /[\310-\313]/g,
      /[\350-\353]/g, // E, e
      /[\314-\317]/g,
      /[\354-\357]/g, // I, i
      /[\322-\330]/g,
      /[\362-\370]/g, // O, o
      /[\331-\334]/g,
      /[\371-\374]/g, // U, u
      /[\321]/g,
      /[\361]/g, // N, n
      /[\307]/g,
      /[\347]/g, // C, c
    ];

    const noaccent = [
      'A',
      'a',
      'E',
      'e',
      'I',
      'i',
      'O',
      'o',
      'U',
      'u',
      'N',
      'n',
      'C',
      'c',
    ];

    for (let i = 0; i < accent.length; i++) {
      str = str.replace(accent[i], noaccent[i]);
    }

    return str;
  }
}
