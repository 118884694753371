import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StateService } from '@core/services/state.service';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
})
export class SearchFieldComponent implements OnInit, OnDestroy {
  @Input() title: any;

  field = new FormControl('');

  placeholder: string;

  state = this.stateService.state;

  constructor(private stateService: StateService) {}

  ngOnInit(): void {
    this.placeholder = `Rechercher un ${this.title}`;
    this.field.valueChanges.subscribe((value: string) => {
      this.stateService.setSearchFilter(value);
    });
  }

  clear() {
    this.stateService.setSearchFilter('');
    this.field.setValue('');
  }

  ngOnDestroy() {
    this.clear();
  }
}
