import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { items } from '@core/constants/menu.constant';

@Component({
  selector: 'app-titre',
  templateUrl: './titre.component.html',
  styleUrls: ['./titre.component.scss']
})
export class TitreComponent implements OnChanges {

  @Input() key: string;
  @Input() add = false;
  @Input() underTitle;
  item: any;

  title: string;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.key || changes.add) {
      this.item = items[this.key];
      this.title = this.item.title;
    }
  }
}
