export class State {
  page: string = null;
  element: string = null;
  id: number = null;
  current: any = null;
  action: string = null;
  forms: {element: string, id?: number}[] = [];
  prev: State = null;

  constructor(options?: {
    page?: string,
    element?: string,
    id?: number,
    current?: any,
    forms?: {element: string, id?: number}[],
    action?: string,
    prev?: State
  }) {
    ['page', 'element', 'id', 'action', 'forms', 'current'].forEach((key: string) => {
      if (options[key] !== undefined) {
        this[key] = options[key];
      }
    });
    if (options.prev) {
      this.prev = new State(options.prev);
    }
  }
}
