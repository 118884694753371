import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { LocalStorageService } from '@offline/localstorage.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { User } from './classes/user.class';
import { ToUserEtlService } from './etl/to-user-etl.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiSegment = 'user';

  user$: BehaviorSubject<User> = new BehaviorSubject(null);

  user: User = null;

  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private toEtl: ToUserEtlService
  ) {}

  getCurrentUser(): Observable<User> {
    return this.localStorageService.getLocalToken().pipe(
      concatMap((token: string) => {
        if (!token) {
          return of(null);
        }

        return this.apiService.get(`${this.apiSegment}/current`).pipe(
          map((data: any) => {
            const user = this.createFromBdd({
              ...data.user,
              contact: data.contact,
            });

            this.user = user;

            return user;
          })
        );
      })
    );
  }

  get(id: number) {
    return this.apiService.get(`${this.apiSegment}/${id}`);
  }

  hasRight(right: string, objectType: string, id: number): Observable<boolean> {
    return this.apiService.get(
      `${this.apiSegment}/rights/${right}/${objectType}/${id}`
    );
  }

  createFromBdd(data: any): User {
    return this.toEtl.etl('initFromBdd', { data });
  }
}
