import { Component, Input } from '@angular/core';
import { MyMessageService } from '@core/services/my-message.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-pop-up-comfirm',
  templateUrl: './pop-up-comfirm.component.html',
  styleUrls: ['./pop-up-comfirm.component.scss'],
})
export class PopUpComfirmComponent {
  @Input() key: string;

  position: string;

  iconVisible = true;

  popUpMessageComfirm = 'Êtes-vous sûr de vouloir supprimer cet élément ?';

  popUpMessage = 'La vie est ainsi faite.';

  popUpMessageSuccess = "L'action à été exécutée.";

  popUpMessageInfo = 'Message à caractère informatif.';

  popUpMessageError = "Impossible d'effectuer cette action.";

  acceptLabel = 'Oui, supprimer';

  rejectLabel = 'Annuler';

  rejectLabelOk = "J'ai compris";

  constructor(
    private confirmationService: ConfirmationService,
    private myMessageService: MyMessageService
  ) {}

  showConfirm(position: string): void {
    this.position = position;
    this.confirmationService.confirm({
      // message:  'msg',
      // header: 'header',
      // icon: 'icon'
      accept: () => {
        // Actual logic to perform a confirmation
      },
      reject: () => {
        // Actual logic to perform a confirmation
      },
      key: this.key,
    });
  }

  showSuccess(): void {
    this.myMessageService.add({
      severity: 'success',
      summary: this.popUpMessageSuccess,
      detail: '',
      sticky: this.iconVisible,
    });
  }

  showInfo(): void {
    this.myMessageService.add({
      severity: 'info',
      summary: this.popUpMessageInfo,
      detail: '',
      sticky: this.iconVisible,
    });
  }

  showError(): void {
    this.myMessageService.add({
      severity: 'warn',
      summary: this.popUpMessageError,
      detail: '',
      sticky: this.iconVisible,
    });
  }
}
