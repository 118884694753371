import { Base } from '@core/classes/base.class';

export class Event extends Base {

  static frontToBackProps = Object.assign({}, Base.frontToBackProps, {
    contactIds: 'contact_ids',
    chefId: 'chef_id',
    tacheId: 'tache_id',
    projetId: 'projet_id',
    allDay: 'all_day'
  });

  static backToFrontProps = Object.assign({}, Base.backToFrontProps, {
    contact_ids: 'contactIds',
    chef_id: 'chefId',
    tache_id: 'tacheId',
    projet_id: 'projetId',
    all_day: 'allDay'
  });


  chefId?: number;
  contactIds?: number;
  tacheId?: number;
  projetId?: number;
  label?: string;
  description?: string;
  allDay?: boolean;
  start?: Date;
  end?: Date;
  color?: string;
  editable?: boolean;

  constructor(options: {
    id?: number,
    provId?: number,
    chefId?: number,
    contactIds?: number,
    tacheId?: number,
    projetId?: number,
    label?: string
    description?: string,
    allDay?: boolean,
    start?: Date,
    end?: Date,
    color?: string,
    editable?: boolean;
  }) {
    Object.keys(Event.frontToBackProps).forEach((key: string) => {
      if (options[key] === undefined && options[Event.frontToBackProps[key]] !== undefined) {
        options[key] = options[Event.frontToBackProps[key]];
      }
    });
    super(options);
    this.initFromArray(options, ['contactIds', 'chefId', 'tacheId', 'projetId', 'label', 'description', 'allDay', 'color', 'editable']);
    if (options.start) {
      this.start = new Date(options.start);
    }
    if (options.end) {
      this.end = new Date(options.end);
    }
  }
}
