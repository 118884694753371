import { Injectable } from '@angular/core';
import { AuthService } from '@auth/shared/auth.service';
import { Document } from '@core/classes/document.class';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { FlowType } from '@offline/flowType.enum';
import { LocalStorageService } from '@offline/localstorage.service';
import { UserService } from '@user/shared/user.service';
import { concatMap, map } from 'rxjs/operators';

import { Dossier } from './classes/dossier.class';
import { FromDossierEtlService } from './etl/from-dossier-etl.service';
import { ToDossierEtlService } from './etl/to-dossier-etl.service';

@Injectable({
  providedIn: 'root',
})
export class DossierService {
  private apiSegment = 'dossier';

  liste: Dossier[] = [];

  current: Dossier;

  documentList: Document[] = [];

  original: Dossier;

  constructor(
    private apiService: ApiService,
    private stateService: StateService,
    private userService: UserService,
    private fromEtl: FromDossierEtlService,
    private toEtl: ToDossierEtlService,
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {}

  count(params = {}) {
    return this.apiService.count(this.apiSegment, params);
  }

  list(params = {}) {
    return this.authService.isOnline$.pipe(
      concatMap((online) => {
        if (online) {
          this.stateService.itemLoaded$.next(false);
          this.current = null;
          this.liste = [];

          return this.apiService.get(`${this.apiSegment}/all`, params).pipe(
            map((items: any[]) => {
              const dossiers = items.map((it: any) => this.createFromBdd(it));

              this.liste = dossiers;
              this.stateService.itemLoaded$.next(true);

              return this.liste;
            }),
            concatMap((items) =>
              this.localStorageService.addAllDossierLocalStorage(items)
            ),
            map(() => this.liste)
          );
        }

        return this.localStorageService.getAllDossierLocalStorage();
      })
    );
  }

  listDocuments(id: number) {
    this.documentList = null;

    return this.apiService.get(`${this.apiSegment}/allDocuments/${id}`).pipe(
      map((ret: Document[]) => {
        this.documentList = ret.map((d) => new Document(d));

        return this.documentList;
      })
    );
  }

  get(id: number, params = {}) {
    return this.authService.isOnline$.pipe(
      concatMap((online) => {
        if (online) {
          this.current = null;

          return this.apiService
            .get(`${this.apiSegment}/one/${id}`, params)
            .pipe(
              map((it: any) => {
                const current = this.createFromBdd(it);

                this.current = current;
                this.stateService.itemLoaded$.next(true);

                return this.current;
              }),
              concatMap((current: Dossier) =>
                this.localStorageService.updateDossierLocalStorage(
                  current,
                  null,
                  id,
                  FlowType.BROWSING,
                  true
                )
              ),
              map(() => this.current)
            );
        }

        return this.localStorageService.getOneDossierLocalStorage(id);
      })
    );
  }

  create(dossier: Dossier) {
    dossier.setChefProjet(this.userService.user.contact);
    const obj = this.transformToBdd(dossier);

    return this.apiService.post(`${this.apiSegment}/create`, obj).pipe(
      map((it: any) => {
        const current = this.createFromBdd(it);

        this.current = current;
        this.stateService.itemLoaded$.next(true);

        return current;
      })
    );
  }

  update(data: any, ref?: string) {
    if (!ref) {
      ref = data.ref;
    }
    const obj = this.transformToBdd(data);

    return this.apiService.put(`${this.apiSegment}/one/${ref}`, obj).pipe(
      map((it: any) => {
        const current = this.createFromBdd(it);

        this.current = current;
        this.stateService.itemLoaded$.next(true);

        return current;
      })
    );
  }

  setNextTache(ref: string) {
    return this.apiService.post(`${this.apiSegment}/one/${ref}/task/next`, {
      closeCurrent: true,
    });
  }

  delete(ref: string | any) {
    if (ref instanceof Object) {
      ref = ref.ref;
    }
    this.current = null;

    return this.apiService
      .delete(`${this.apiSegment}/one/${ref}`)
      .pipe(map(() => this.localStorageService.deleteDossierLocalStorage(ref)));
  }

  archive(id: number | any, archive = false) {
    this.current = null;
    if (id instanceof Object) {
      id = id.id;
    }

    return this.apiService.put(`${this.apiSegment}/one/${id}`, {
      archive,
    });
  }

  createFromBdd(data: any): Dossier {
    // Tools.log('data', data)();
    const dossier = this.toEtl.etl('initFromBdd', { data });

    // Tools.log('dossier', dossier)();
    return dossier;
  }

  transformToBdd(dossier: Dossier): any {
    const data = this.fromEtl.etl('transformToBdd', { model: dossier });

    return data;
  }
}
