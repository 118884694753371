import { Contact } from '@contact/shared/classes/contact.class';
import { Base } from '@core/classes/base.class';

export class User extends Base {
  roles?: string[];

  username?: string;

  contact?: Contact;

  constructor(options: {
    id?: number;
    provId?: number;
    roles?: string[];
    username?: string;
    contact?: Contact;
  }) {
    super(options);
    this.initFromArray(options, ['username', 'roles', 'contact']);
  }

  protected initFromArray(options?: any, keys?: string[]) {
    if (options && keys && keys.length) {
      keys.forEach((key: string) => {
        if (options[key] !== undefined) {
          if (key === 'contact') {
            this[key] = new Contact(options[key]);
          } else {
            this[key] = options[key];
          }
        }
      });
    }
  }

  isAdmin() {
    return this.roles.includes('adminartiz');
  }

  isSuperAdmin() {
    return this.roles.includes('superadmin');
  }
}
