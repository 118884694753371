import { Injectable } from '@angular/core';
import { StateService } from '@core/services/state.service';
import { ArtizLabelPipe } from '@shared/pipes/artiz-label.pipe';

@Injectable({
  providedIn: 'root'
})
export class MenuItems {
  agendaViewMenuItems = [
    {
      id: 'Month',
      label: 'Mois',
      command: ($event: any) => this.stateService.headerMenuClick($event)
    },
    {
      id: 'Week',
      label: 'Semaine',
      command: ($event: any) => this.stateService.headerMenuClick($event)
    },
    {
      id: 'Day',
      label: 'Jour',
      command: ($event: any) => this.stateService.headerMenuClick($event)
    }
  ];

  chatProfileMenuItems = [
    {
      id: 'ShowProfile',
      label: 'Voir le profil',
      command: ($event: any) => this.stateService.headerMenuClick($event)
    },
    {
      id: 'DeleteConversation',
      label: 'Supprimer la conversation',
      command: ($event: any) => this.stateService.headerMenuClick($event)
    },
  ];

  chatGroupMenuItems = [
    {
      id: 'ModifyGroup',
      label: 'A propos du groupe',
      command: ($event: any) => this.stateService.headerMenuClick($event)
    },
  ];

  constructor(
    private stateService: StateService,
    private artizLabel: ArtizLabelPipe,
  ) { }

  getMenuModel(model: string) {
    switch (model) {
      case 'agendaViewMenuItems':
        return this.agendaViewMenuItems;
      case 'chatProfileMenuItems':
        return this.chatProfileMenuItems;
      case 'chatGroupMenuItems':
        return this.chatGroupMenuItems;
      case 'uploadMenuItems':
        return this.chatGroupMenuItems;
      default:
        throw(new Error('Unknown model error: ' + model));
    }
  }

}
