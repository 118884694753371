import { Component, Input } from '@angular/core';

import { Coordonnees } from '@core/classes/coordonnees.class';

@Component({
  selector: 'app-coordonnees',
  templateUrl: './coordonnees.component.html',
  styleUrls: ['./coordonnees.component.scss']
})
export class CoordonneesComponent {

  // keys = [
  //   'tel',
  //   'email',
  //   'adresse',
  //   'codePostal',
  //   'ville'
  // ];

  keys = [
    {
      label: 'tel',
      icon: 'phone'
    },
    {
      label: 'email',
      icon: 'envelope'
    },
    // {
    //   label: 'adresse',
    //   icon: 'pin'
    // },
    // {
    //   label: 'codePostal',
    //   icon: 'pin'
    // },
    // {
    //   label: 'ville',
    //   icon: 'pin'
    // },
  ];

  @Input() short = false;
  @Input() coordonnees: Coordonnees;

}
