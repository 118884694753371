import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/shared/auth.service';
import { items as menuItems } from '@core/constants/menu.constant';
import { StateService } from '@core/services/state.service';
import { IMenuItemConstant } from '@shared/interfaces/i-menu-item-constant';
import { User } from '@user/shared/classes/user.class';
import { UserService } from '@user/shared/user.service';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnDestroy, OnInit {
  userSubscription: Subscription;

  topLeftKeys = ['home'];

  // topRightKeys = ['signout', 'settings', 'notifications'];
  topRightKeys = ['signout'];

  // middleKeys = ['dossierlist', 'chatlist', 'contactlist', 'agendalist', 'tachelist', 'notes', 'jobs', 'gestion', 'aidesaides'];
  middleKeys = [
    'dossierlist',
    'chatlist',
    'contactlist',
    'agendalist',
    'tachelist',
    'aidesaides',
  ];

  disableMiddleIcon = [];

  bottomKeys = ['help', 'aproposlist', 'bibliothequelist'];

  topLeftItems: Array<MenuItem> = [];

  topRightItems: Array<MenuItem> = [];

  middleItems: Array<MenuItem> = [];

  bottomItems: Array<MenuItem> = [];

  user: User;

  constructor(
    private stateService: StateService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.userService.user$.subscribe((user: User) => {
      this.user = user;
    });
    this.topLeftItems = this.getItems(this.topLeftKeys, true);
    this.topRightItems = this.getItems(this.topRightKeys, true);
    this.middleItems = this.getItems(this.middleKeys, true);
    this.bottomItems = this.getItems(this.bottomKeys, true);
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  itemClick(itemClicked: { item: MenuItem }): void {
    const key = itemClicked.item.id;

    const item: IMenuItemConstant = menuItems[key] as IMenuItemConstant;

    this.stateService.setMenu(false);
    if (key === 'signout') {
      this.authService.signout().subscribe();

      return;
    }
    if (item.link) {
      const { queryParams } = item;

      if (item.link === '/agenda' && queryParams) {
        queryParams.date = new Date().toISOString().slice(0, 10);
      }
      this.router.navigate(
        item.link instanceof Array ? item.link : [item.link],
        queryParams ? { queryParams } : {}
      );
    }
  }

  private getItems(keys: string[], label?: boolean): MenuItem[] {
    return keys.map((key: string) => {
      const item: IMenuItemConstant = menuItems[key] as IMenuItemConstant;

      const menuItem: MenuItem & { color?: string } = { id: key };

      if (label && item.title) {
        menuItem.label = item.menuTitle;
      }
      if (item.icon) {
        menuItem.icon = `icon icon-${item.menuIcon}`;
      }
      if (item.color) {
        menuItem.color = item.menuColor;
      }
      menuItem.command = (_: { item: MenuItem }): void => this.itemClick(_);

      return menuItem;
    });
  }
}
