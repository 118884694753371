import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { Document } from '../classes/document.class';
import { map, concatMap } from 'rxjs/operators';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  apiSegment = `document`;
  apiSegmentUser = `user`;
  apiSegmentContact = `contact`;
  apiSegmentMessage = `message`;

  uploadSuccess: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  deleteSuccess: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private apiService: ApiService,
  ) {}

  get(id: number) {
    return this.apiService.get(`${this.apiSegment}/one/${id}`, null, 'blob');
  }

  getUserPicture(id: number) {
    return this.apiService.get(`${this.apiSegmentUser}/picture/${id}`, null, 'blob');
  }

  getContactPicture(id: number) {
    return this.apiService.get(`${this.apiSegmentContact}/picture/${id}`, null, 'blob');
  }

  create(doc: Document, file: File, notify = true) {
    return this.prepareUpload(doc, file).pipe(
      concatMap((formData) => {
        return this.apiService.post(`${this.apiSegment}/create`, formData).pipe(
          map((ret: Document) => {
            const docu = new Document(ret);
            if (notify) {
              this.uploadSuccess.next(docu.erpId);
              this.uploadSuccess.next(0);
            }
            return docu;
          })
        );
      })
    );
  }

  createImage(doc: Document, file: File, notify = true) {
    return this.prepareUpload(doc, file).pipe(
      concatMap((formData) => {
        return this.apiService.post(`${this.apiSegment}/createImage`, formData).pipe(
          map((ret: Document) => {
            const docu = new Document(ret);
            if (notify) {
              this.uploadSuccess.next(docu.erpId);
              this.uploadSuccess.next(0);
            }
            return docu;
          })
        );
      })
    );
  }

  update(doc: Document, file: File) {
    return this.prepareUpload(doc, file).pipe(
      concatMap((formData) => {
        return this.apiService.put(`${this.apiSegment}/update/${doc.erpId}`, formData).pipe(
          map((ret: Document) => {
            const docu = new Document(ret);
            this.uploadSuccess.next(docu.erpId);
            this.uploadSuccess.next(0);
            return docu;
          })
        );
      })
    );
  }

  uploadUserPicture(id: number, file: File, notify = true) {
    const doc = new Document({
      type: 0,
    });
    return this.prepareUpload(doc, file).pipe(
      concatMap((formData) => {
        return this.apiService.post(`${this.apiSegmentUser}/picture/${id}`, formData).pipe(
          map((ret: Document) => {
            const docu = new Document(ret);
            if (notify) {
              this.uploadSuccess.next(docu.erpId);
              this.uploadSuccess.next(0);
            }
            return docu;
          })
        );
      })
    );
  }

  uploadContactPicture(id: number, file: File, notify = true) {
    return this.prepareUpload(null, file).pipe(
      concatMap((formData) => {
        return this.apiService.post(`${this.apiSegmentContact}/picture/${id}`, formData).pipe(
          map((ret: Document) => {
            const docu = new Document(ret);
            if (notify) {
              this.uploadSuccess.next(docu.erpId);
              this.uploadSuccess.next(0);
            }
            return docu;
          })
        );
      })
    );
  }

  uploadToConversation(conversationId: string, participantId: string, doc: Document, file: File, notify = true) {
    return this.prepareUpload(doc, file).pipe(
      concatMap((formData) => {
        return this.apiService.post(`${this.apiSegmentMessage}/upload/${conversationId}/${participantId}`, formData).pipe(
          map((ret: any) => {
            if (notify) {
              this.uploadSuccess.next(ret);
              this.uploadSuccess.next(0);
            }
            return ret;
          })
        );
      })
    );
  }

  uploadToConversationInternal(conversationId: string, participantId: string, doc: Document, notify = true) {
    return this.apiService.post(`${this.apiSegmentMessage}/uploadInternal/${conversationId}/${participantId}`,
      { erp_id: doc.erpId.toString()
        }
      ).pipe(
      map((ret: any) => {
        if (notify) {
          this.uploadSuccess.next(ret);
          this.uploadSuccess.next(0);
        }
        return ret;
      })
    );
  }

  private prepareUpload(doc: Document, file: File) {
    return Observable.create((observable) => {
      const fileReader = new FileReader();
      fileReader.onload = (() => {
        const formData: FormData = new FormData();

        if (doc) {
          if (!doc.extension) {
            throw new Error('File without extension');
          }

          const extractedFilename = doc.baseFilename;
          let editedFilename: string;
          if (extractedFilename.length >= 150) {
            editedFilename = extractedFilename.substring(0, 150) + '.' + doc.extension;
          } else {
            editedFilename = extractedFilename + '.' + doc.extension;
          }

          if (doc.type) {
            formData.append('type', doc.type.toString());
          }
          if (doc.label) {
            formData.append('label', editedFilename);
          }
          if (doc.dossierId) {
            formData.append(Document.frontToBackProps.dossierId, doc.dossierId.toString());
          }
          if (doc.commentaire) {
            formData.append('commentaire', doc.commentaire.commentaire.toString());
          }
          if (doc.typeElementId) {
            formData.append(Document.frontToBackProps.typeElementId, doc.typeElementId.toString());
          }
          if (doc.elementId) {
            formData.append(Document.frontToBackProps.elementId, doc.elementId.toString());
          }
        }
        formData.append('file', file);

        observable.next(formData);
        observable.complete();
      });
      fileReader.readAsDataURL(file);
    });
  }

  delete(id: number) {
    return this.apiService.delete(`${this.apiSegment}/one/${id}`).pipe(
      map((fileId: number) => {
        this.deleteSuccess.next(Number(fileId));
        this.deleteSuccess.next(0);
        return Number(fileId);
      })
    );
  }

  private getFilename(path: string) {
    const basename = path.split(/[\\/]/).pop();
    const pos = basename.lastIndexOf('.');

    if (basename === '' || pos < 1) {
        return path;
    }
    return basename.slice(0, pos);
  }

  private getExtension(path: string) {
    const basename = path.split(/[\\/]/).pop();
    const pos = basename.lastIndexOf('.');

    if (basename === '' || pos < 1) {
        return '';
    }
    return basename.slice(pos + 1);
  }

  public reactiveReadFile(file: File): Observable<string|ArrayBuffer> {
    return Observable.create((observable) => {
      const fileReader = new FileReader();
      fileReader.onload = (() => {
        observable.next(fileReader.result);
        observable.complete();
      });
      fileReader.readAsDataURL(file);
    });
  }

  public openToAnotherWindow(file: any) {
    const url = window.URL.createObjectURL(file);
    window.open(url);
  }

  public saveToDisk(file: any, filename: string) {
    const url = window.URL.createObjectURL(file);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }

  getAllFiche(): Observable<Document[]> {
    return this.apiService.get(`${this.apiSegment}/fiche/all`).pipe(
      map((ret: any[]) => {
        const fiche = ret.map((d) => new Document(d));
        return fiche;
      })
    );
  }

  getOneFiche(id: string) {
    return this.apiService.get(`${this.apiSegment}/fiche/one/${id}`, null, 'blob').pipe(
      map((fiche: any) => {
        return fiche;
      })
    );
  }
}
