import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { Contact } from './classes/contact.class';
import { FromContactEtlService } from './etl/from-contact-etl.service';
import { ToContactEtlService } from './etl/to-contact-etl.service';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private apiSegment = 'contact';

  current: Contact;

  image$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private apiService: ApiService,
    private stateService: StateService,
    private toEtl: ToContactEtlService,
    private fromEtl: FromContactEtlService
  ) {}

  list(params = {}): Observable<any> {
    this.stateService.itemLoaded$.next(false);
    this.current = null;

    return this.apiService.get(`${this.apiSegment}/all`, params).pipe(
      map((items: any[]) => items.map((it: any) => this.createFromBdd(it))),
      tap(() => this.stateService.itemLoaded$.next(true))
    );
  }

  get(id: number) {
    return this.apiService.get(`${this.apiSegment}/one/${id}`).pipe(
      map((it: any) => {
        const contact = this.createFromBdd(it);

        this.current = contact;
        this.stateService.itemLoaded$.next(true);

        return contact;
      })
    );
  }

  getProjectsNb(id: number, params = null): Observable<any> {
    return this.apiService.get(`${this.apiSegment}/projectsnb/${id}`, params);
  }

  create(data: any): Observable<any> {
    if (!data.tags && data.typeContact) {
      data.tags = [data.typeContact];
    }
    const obj = this.transformToBdd(this.createFromForm(data));

    if (data.password) {
      obj.password = data.password;
    }

    return this.apiService.post('auth/signup', obj);
  }

  update(data: any, id?: number) {
    if (!id) {
      id = this.current.id;
    }
    this.current = this.updateFromForm(this.current, data);
    const obj = this.transformToBdd(this.current);

    return this.apiService.put(`${this.apiSegment}/one/${id}`, obj);
  }

  delete(id: number | any) {
    if (id instanceof Object) {
      id = id.id;
    }

    return this.apiService.delete(`${this.apiSegment}/one/${id}`);
  }

  createFromBdd(data: any): Contact {
    const contact = this.toEtl.etl('initFromBdd', { data });

    return contact;
  }

  createFromForm(data: any): Contact {
    const contact = this.toEtl.etl('createFromForm', { data });

    return contact;
  }

  updateFromForm(model: Contact, data: any): Contact {
    const contact = this.toEtl.etl('updateFromForm', { model, data });

    return contact;
  }

  transformToBdd(contact: Contact): any {
    const data = this.fromEtl.etl('transformToBdd', { model: contact });

    return data;
  }
}
