import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Contact } from '@contact/shared/classes/contact.class';
import { StateService } from '@core/services/state.service';
import { ArtizLabelPipe } from '@shared/pipes/artiz-label.pipe';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-modal-menu',
  templateUrl: './modal-menu.component.html',
  styleUrls: ['./modal-menu.component.scss'],
  providers: [ArtizLabelPipe],
})
export class ModalMenuComponent implements OnInit {
  segments = ['', 'contact'];

  @Input() visible = false;

  @Output() visibleChange = new EventEmitter<boolean>();

  context: string;

  @Input()
  get inputContext(): string {
    return this.context;
  }

  set inputContext(context: string) {
    this.context = context;
    this.setItems();
  }

  @Input() contact: Contact;

  @Input() type: string;

  @Input() headerText: string;

  actionLabel: string;

  items: MenuItem[] = [];

  constructor(
    private artizLabel: ArtizLabelPipe,
    private router: Router,
    private stateService: StateService
  ) {}

  ngOnInit(): void {
    this.setItems();
  }

  hideElement() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  itemClick(item: any): void {
    this.visible = false;
    if (this.context !== 'add' && this.context !== 'edit') {
      item.command(item);
    } else {
      const segments = this.segments.slice();

      if (item.id === 'add') {
        segments.push(item.id);
      } else if (item.id === 'edit') {
        segments.push(this.contact.id.toString());
        segments.push(item.id);
      }
      this.router.navigate(segments, { queryParams: { type: this.type } });
    }
  }

  private setItems(): void {
    if (this.context === 'add' || this.context === 'edit') {
      this.items = [
        {
          id: 'search',
          label: this.artizLabel.transform('SELECT_FROM_RESEAU_LABEL'),
          command: ($event) => this.itemClick($event),
        },
        {
          id: 'add',
          label: this.artizLabel.transform('CREATE_NEW_LABEL'),
          command: ($event) => this.itemClick($event),
        },
      ];
    } else if (this.context === 'chat') {
      this.items = [
        {
          id: 'view',
          label: this.artizLabel.transform('SEE_CONTACT'),
          command: ($event) => this.stateService.modalMenuClick($event),
        },
        {
          id: 'message',
          label: this.artizLabel.transform('SEND_MESSAGE'),
          command: ($event) => this.stateService.modalMenuClick($event),
        },
        /* {
          id: 'admin',
          label: this.artizLabel.transform('SET_AS_ADMIN'),
          command: ($event) => this.stateService.modalMenuClick($event)
        }, */
        {
          id: 'remove',
          label: this.artizLabel.transform('REMOVE_FROM_GROUP'),
          command: ($event) => this.stateService.modalMenuClick($event),
        },
      ];
    } else if (this.context === 'chatme') {
      this.items = [
        {
          id: 'remove',
          label: this.artizLabel.transform('REMOVE_FROM_GROUP'),
          command: ($event) => this.stateService.modalMenuClick($event),
        },
      ];
    } else if (this.context === 'upload') {
      this.items = [
        {
          id: 'cancel',
          icon: 'remove',
          label: this.artizLabel.transform('ADD_FILE'),
          command: ($event) => this.stateService.headerMenuClick($event),
        },
        {
          id: 'artiz',
          icon: 'folder',
          label: this.artizLabel.transform('FROM_FOLDER'),
          command: ($event) => this.stateService.headerMenuClick($event),
        },
        {
          id: 'device',
          icon: 'phone-action-search',
          label: this.artizLabel.transform('FROM_DEVICE'),
          command: ($event) => this.stateService.headerMenuClick($event),
        },
      ];
    } else {
      this.items = [
        {
          id: 'search',
          label: this.artizLabel.transform('SELECT_FROM_RESEAU_LABEL'),
          command: ($event) => this.itemClick($event),
        },
        {
          id: 'edit',
          label: this.artizLabel.transform('MODIFY_EXISTANT_LABEL'),
          command: ($event) => this.itemClick($event),
        },
      ];
    }
  }
}
