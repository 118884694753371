import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';


@Component({
  selector: 'app-date-heure',
  templateUrl: './date-heure.component.html',
  styleUrls: ['./date-heure.component.scss']
})
export class DateHeureComponent implements OnDestroy, OnInit {

  dateSubscription: Subscription;
  date: Date;

  constructor() { }

  ngOnInit(): void {
    this.dateSubscription = timer(0, 1000).subscribe(() => this.date = new Date());
  }

  ngOnDestroy() {
    this.dateSubscription.unsubscribe();
  }
}
