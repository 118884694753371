import { Artisan } from '@contact/shared/classes/artisan.class';
import { Client } from '@contact/shared/classes/client.class';
import { Contact } from '@contact/shared/classes/contact.class';
import { Base } from '@core/classes/base.class';
import { Tache } from '@tache/shared/classes/tache.class';

export class Dossier extends Base {
  static frontToBackProps = {
    ...Base.frontToBackProps,
    dateStart: 'date_start',
    dateEnd: 'date_end',
    chefProjet: 'chef',
  };

  static backToFrontProps = {
    ...Base.backToFrontProps,
    date_start: 'dateStart',
    date_end: 'dateEnd',
    chef: 'chefProjet',
  };

  chefProjet?: Artisan;

  chantier?: Contact;

  client?: Client;

  artisans?: Artisan[];

  taches?: Tache[];

  statut?: number;

  ref?: string;

  description?: string;

  dateStart?: Date;

  dateEnd?: Date;

  droits?: string;

  archive?: boolean;

  status?: string;

  constructor(options?: {
    id?: number;
    provId?: number;
    chantier?: Contact;
    client?: Client;
    chefProjet?: Artisan;
    artisans?: Artisan[];
    taches?: Tache[];
    statut?: number;
    ref?: string;
    description?: string;
    dateStart?: string | Date;
    dateEnd?: string | Date;
    droits?: string;
    archive?: boolean;
    status?: string;
  }) {
    Object.keys(Dossier.frontToBackProps).forEach((key: string) => {
      if (
        options[key] === undefined
        && options[Dossier.frontToBackProps[key]] !== undefined
      ) {
        options[key] = options[Dossier.frontToBackProps[key]];
      }
    });
    super(options);
    this.initFromArray(options, [
      'chantier',
      'chefProjet',
      'client',
      'artisans',
      'taches',
      'archive',
      'status',
      'ref',
      'description',
      'dateStart',
      'dateEnd',
      'droits',
    ]);
    this.setSearch();
  }

  protected initFromArray(options?: any, keys?: string[]) {
    if (options && keys && keys.length) {
      keys.forEach((key: string) => {
        if (options[key] !== undefined) {
          if (
            ['dateDebut', 'dateFin'].includes(key)
            && typeof options[key] === 'string'
          ) {
            this[key] = new Date(options[key]);
          } else if (key === 'chefProjet') {
            this[key] = new Artisan(options[key]);
          } else if (key === 'chantier') {
            this[key] = new Contact(options[key]);
          } else if (key === 'client') {
            this[key] = new Client(options[key]);
          } else if (key === 'artisans') {
            if (!options[key] || !(options[key] instanceof Array)) {
              this[key] = [];
            } else {
              this[key] = options[key].map((a: any) => new Artisan(a));
            }
          } else if (key === 'taches') {
            if (!options[key] || !(options[key] instanceof Array)) {
              this[key] = [];
            } else {
              this[key] = options[key].map((a: any) => new Tache(a));
            }
          } else {
            this[key] = options[key];
          }
        }
      });
    }
  }

  setChefProjet(obj: Contact | any) {
    if (!obj) {
      this.chefProjet = null;
    } else if (obj instanceof Contact) {
      this.chefProjet = obj;
    } else {
      this.chefProjet = new Contact(obj);
    }
  }

  setClient(obj: Contact | any) {
    if (!obj) {
      this.client = null;
    } else if (obj instanceof Client) {
      this.client = obj;
    } else {
      this.client = new Client(obj);
    }
  }

  setChantier(obj: Contact | any) {
    if (!obj) {
      this.chantier = null;
    } else if (obj instanceof Contact) {
      this.chantier = obj;
    } else {
      this.chantier = new Contact(obj);
    }
  }

  setTaches(taches: any[]) {
    taches.forEach((t: any) => {
      const tache = this.taches.find((tt: Tache) => tt.id === t.id);

      if (tache) {
        tache.progress = t.progress ? 100 : 0;
      }
    });
  }

  setStatus(status: string) {
    this.archive = !!['WON', 'LOST'].includes(status);
    this.status = status;
  }

  setSearch(): void {
    this.search = this.ref;
    if (this.client) {
      this.search += this.client.label;
    }
    if (this.chantier && this.chantier.coordonnees) {
      this.search +=
        this.chantier.coordonnees.adresse
        + this.chantier.coordonnees.codePostal
        + this.chantier.coordonnees.ville;
    }
  }
}
