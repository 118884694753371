import { Injectable } from '@angular/core';
import { AbstractEtlService } from '@core/etl/shared/abstract-etl.service';
// ------------------------------------ EXTERNS
import decamelize from 'decamelize';

// ------------------------------------ CLASSES
import { Event } from '../classes/event.class';
// ------------------------------------ SERVICES
import { EventEtlService } from './event-etl.service';

@Injectable({
  providedIn: 'root',
})
export class FromEventEtlService
  extends EventEtlService
  implements AbstractEtlService<Event, Event, any>
{
  constructor() {
    super();
  }

  // ----------------------------------------> Load
  load() {
    if (this.context === 'transformToBdd') {
      this.dest = this.transformDataToBdd();
    }
  }

  // ----------------------------------------> Transform (updateToBdd)

  protected transformDataToBdd() {
    if (this.model) {
      const obj = this.transformObject(this.model);

      obj.start = Math.round(obj.start.getTime() / 1000);
      obj.end = Math.round(obj.end.getTime() / 1000);

      return obj;
    }

    return null;
  }

  protected transformObject(obj: any) {
    if (!obj) {
      return obj;
    }
    if (
      typeof obj === 'boolean'
      || typeof obj === 'number'
      || typeof obj === 'string'
      || obj instanceof Date
    ) {
      return obj;
    }
    const data = {};

    Object.entries(obj).forEach((v) => {
      if (!v[1]) {
        data[decamelize(v[0])] = v[1];
      } else if (v[1] instanceof Array) {
        data[decamelize(v[0])] = v[1].map((value) =>
          this.transformObject(value)
        );
      } else if (v[1] instanceof Object) {
        data[decamelize(v[0])] = this.transformObject(v[1]);
      } else {
        data[decamelize(v[0])] = v[1];
      }
    });

    return data;
  }
}
