import { Injectable } from '@angular/core';
import { AbstractEtlService } from '@core/etl/shared/abstract-etl.service';

// ------------------------------------ CLASSES
import { User } from '../classes/user.class';
// ------------------------------------ SERVICES
import { UserEtlService } from './user-etl.service';

@Injectable({
  providedIn: 'root',
})
export class ToUserEtlService
  extends UserEtlService
  implements AbstractEtlService<User, { data: any; model: any }, User>
{
  constructor() {
    super();
  }

  extract() {
    if (this.context === 'initFromBdd') {
      this.orig = this.transformData(this.data.data, User);

      return;
    }
    super.extract();
  }

  transform() {
    super.transform();

    if (this.modelService) {
      this.modelService.provId = this.provId;
    }
  }
}
