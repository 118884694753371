import { IGeometry } from '@shared/interfaces/i-geometry';

import { Base } from './base.class';

export class Coordonnees extends Base {
  static frontToBackProps = {
    ...Base.frontToBackProps,
    codePostal: 'code_postal',
    codeInsee: 'code_insee',
  };

  static backToFrontProps = {
    ...Base.backToFrontProps,
    code_postal: 'codePostal',
    code_insee: 'codeInsee',
  };

  tel?: string;

  email?: string;

  adresse?: string;

  codePostal?: string;

  codeInsee?: string;

  ville?: string;

  pays?: string;

  context?: string;

  geometry?: IGeometry;

  constructor(options: {
    id?: number;
    provId?: number;
    tel?: string;
    email?: string;
    adresse?: string;
    codePostal?: string;
    codeInsee?: string;
    ville?: string;
    pays?: string;
    context?: string;
    geometry?: IGeometry;
  }) {
    Object.keys(Coordonnees.frontToBackProps).forEach((key: string) => {
      if (
        options[key] === undefined
        && options[Coordonnees.frontToBackProps[key]] !== undefined
      ) {
        options[key] = options[Coordonnees.frontToBackProps[key]];
      }
    });
    super(options);
    this.initFromArray(options, [
      'tel',
      'email',
      'adresse',
      'codePostal',
      'codeInsee',
      'ville',
      'pays',
      'context',
      'geometry',
    ]);
  }

  getAdresseResume(): string {
    const elts = [];

    if (this.adresse) {
      elts.push(this.adresse);
    }
    if (this.codePostal) {
      elts.push(this.codePostal);
    }
    if (this.ville) {
      elts.push(this.ville);
    }
    if (this.pays) {
      elts.push(this.pays);
    }

    return elts.join(' ');
  }
}
