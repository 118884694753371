import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-form-steps',
  templateUrl: './form-steps.component.html',
  styleUrls: ['./form-steps.component.scss']
})
export class FormStepsComponent {

  @Output() changePoint = new EventEmitter();

  constructor() { }

  @Input() items: string[] = [];
  @Input() selected: any;
  @Input() disabled: any;

}
