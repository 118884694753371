import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class TestdbService {

  private apiSegment = 'testdb';

  constructor(
    private apiService: ApiService
  ) { }

  get(): Observable<string> {
    return this.apiService.get(this.apiSegment).pipe(
      map((it: any) => {
        if (!it) {
          return null;
        }
        return it;
      })
    );
  }
}
