import { Base } from './base.class';

export class Commentaire extends Base {

  public static frontToBackProps = Object.assign({}, Base.frontToBackProps, {
    dossierId: 'dossier_id',
    typeElementId: 'type_element_id',
  });

  public static backToFrontProps = Object.assign({}, Base.backToFrontProps, {
    dossier_id: 'dossierId',
    type_element_id: 'typeElementId',
  });

  id: number;
  typeElementId: number;
  commentaire: string;
  dossierId: number;

  constructor(options?: {
    id?: number,
    provId?: number,
    typeElementId?: number,
    commentaire?: string,
    dossierId?: number,
  }) {
    Object.keys(Commentaire.frontToBackProps).forEach((key: string) => {
      if (options !== undefined && options[key] === undefined && options[Commentaire.frontToBackProps[key]] !== undefined) {
        options[key] = options[Commentaire.frontToBackProps[key]];
      }
    });
    super(options);
    this.initFromArray(options, ['typeElementId', 'commentaire', 'dossierId']);
  }
}
