import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Document } from '@core/classes/document.class';
import { UploadType } from '@core/classes/uploadType.class';
import { DocumentService } from '@core/services/document.service';
import { Tools } from '@shared/tools';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss'],
})
export class DocumentFormComponent implements OnInit {
  @Input() itemId: number;

  @Input() documents: [];

  @Input() participantId: string;

  @Input() conversationId: string;

  @ViewChild('imageUploader') imageUploader;

  @ViewChild('documentUploader') documentUploader;

  @ViewChild('chatUploader') chatUploader;

  constructor(private documentService: DocumentService) {}

  ngOnInit(): void {}

  public createDocument(uploadType: string, files: FileList) {
    let i = 0;

    const observableArray = new Array<Observable<any>>();

    for (i; i < files.length; i++) {
      const doc = new Document({
        type: 1,
        label: files[i].name,
        dossierId: this.itemId,
        // typeElementId: null,
        // elementId: 0,
      });

      if (uploadType === 'image') {
        observableArray.push(
          this.documentService.createImage(doc, files.item(i))
        );
      } else {
        observableArray.push(this.documentService.create(doc, files.item(i)));
      }
    }

    // reset inputs
    this.imageUploader.nativeElement.value = '';
    this.documentUploader.nativeElement.value = '';

    forkJoin(observableArray).subscribe(() => {});
  }

  public uploadChat(files: FileList) {
    const doc = new Document({
      type: 1,
      label: files[0].name,
      // dossierId: this.itemId,
      // typeElementId: null,
      // elementId: 0,
    });

    this.documentService
      .uploadToConversation(
        this.conversationId,
        this.participantId,
        doc,
        files.item(0)
      )
      .subscribe((ret) => {
        Tools.log('upload conversation')();
      });
    this.chatUploader.nativeElement.value = '';
  }

  public displayFilePicker(uploadType: UploadType) {
    Tools.log(uploadType)();
    if (uploadType.selection === UploadType.image) {
      document.getElementById('uploader-image').click();
    } else if (uploadType.selection === UploadType.doc) {
      document.getElementById('uploader-doc').click();
    } else if (uploadType.selection === UploadType.chatUpload) {
      document.getElementById('uploader-chat').click();
    }
  }

  public updateDocument(doc: Document, files: FileList) {
    return this.documentService.update(doc, files[0]).subscribe((file) => {});
  }

  public deleteDocument(doc: Document) {
    return this.documentService.delete(doc.erpId).subscribe((fileId) => {});
  }
}
