import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Contact } from '@contact/shared/classes/contact.class';
import { ApiService } from '@core/services/api.service';
import { DeviceService } from '@core/services/device.service';
import { environment } from '@environments/environment';
import { Tools } from '@shared/tools';
import { User } from '@user/shared/classes/user.class';
import { UserService } from '@user/shared/user.service';
import { from, Observable, of } from 'rxjs';
import Talk from 'talkjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  apiUrl = `${environment.backApiUrl}`;

  apiSegment = 'message';

  user: User;

  me: Talk.User;

  talkSession: Talk.Session;

  private newConversation;

  private lastConversation: Talk.ConversationData;

  conversation: Talk.ConversationData;

  participant: Talk.UserData;

  other: Talk.UserData[];

  baseConversationId: string;

  constructor(
    private userService: UserService,
    private apiService: ApiService,
    private router: Router,
    private deviceService: DeviceService
  ) {}

  initChatSession(baseConversationId?: string): Observable<Talk.Session> {
    if (
      this.talkSession
      && baseConversationId !== 'new'
      && !baseConversationId
    ) {
      return of(this.talkSession);
    }
    if (baseConversationId && baseConversationId !== 'new') {
      this.baseConversationId = baseConversationId;
    }
    this.user = this.userService.user$.value;

    return from(
      Talk.ready.then(() => {
        this.me = this.createTalkUser(this.user.contact);
        this.talkSession = new Talk.Session({
          appId: environment.talkjsApiKey,
          me: this.me,
        });

        return this.talkSession;
      })
    );
  }

  startConversion(contacts: Contact[]) {
    this.cleanParameters();

    const others = contacts.map((contact) => this.createTalkUser(contact));

    this.newConversation = null;
    this.newConversation = this.talkSession.getOrCreateConversation(
      others.length === 1
        ? Talk.oneOnOneId(this.me, others[0])
        : this.groupId(
            Number(this.me.id),
            others.map((other) => Number(other.id))
          )
    );

    this.newConversation.setParticipant(this.me);
    others.forEach((other) => {
      this.newConversation.setParticipant(other);
    });

    const conversation: Partial<Talk.ConversationData> = {};
    // conversation.id = this.newConversation.id;

    // this.conversation = conversation;
    // this.newConversation.participants.splice(0, 0);

    this.other = this.newConversation.participants.map(
      (participant, index: number) => {
        if (index === 0) {
          this.participant = participant.user;
        }

        return participant.user;
      }
    );
    this.other.splice(0, 1);
    Tools.log(this.other)();
  }

  createTalkUser(contact: Contact) {
    return new Talk.User({
      id: contact.id,
      name: contact.label,
      email: [contact.coordonnees.email],
      role: 'tester',
      photoUrl: `${this.apiUrl}/contact/picture/${contact.id}`,
      locale: 'fr-FR',
    });
  }

  getConversationBuilder(): Talk.ConversationBuilder {
    let conversation = this.newConversation;

    if (this.baseConversationId) {
      conversation = this.talkSession.getOrCreateConversation(
        this.baseConversationId
      );
    }

    if (!conversation && this.lastConversation) {
      conversation = this.talkSession.getOrCreateConversation(
        this.lastConversation.id
      );
      this.conversation = this.lastConversation;
      this.lastConversation = null;
    }

    this.newConversation = null;
    Tools.log(conversation)();

    return conversation;
  }

  getConversationId(): string {
    return this.conversation ? this.conversation.id : null;
  }

  getMe(): Talk.UserData {
    return this.participant;
  }

  getOther(): Talk.UserData[] {
    return this.other;
  }

  setConversation(info: Talk.ConversationSelectedEvent) {
    if (info) {
      this.conversation = info.conversation;
      this.participant = info.me;
      this.other = info.others;
    }
    this.lastConversation = info.conversation;
  }

  hasConversation(): boolean {
    return !!(
      this.lastConversation
      || this.newConversation
      || this.conversation
    );
  }

  groupId(meId: number, otherId: number[]): string {
    let idArray = new Array<number>();

    idArray.push(meId);
    idArray = idArray.concat(otherId);

    return JSON.stringify(idArray.sort().toString());
  }

  goToConversation(newConversation?: boolean) {
    if (this.deviceService.isMobile()) {
      if (newConversation) {
        this.router.navigate(['chat/' + 'new' + '/view']);
      } else if (this.conversation && this.conversation.id) {
        this.router.navigate([`chat/${this.conversation.id}/view`]);
      } else {
        this.router.navigate([`chat/${this.baseConversationId}/view`]);
      }
    } else {
      this.router.navigate(['chat']);
    }
  }

  createOrUpdateUser(contact: Contact) {
    return this.apiService.put(`${this.apiSegment}/user/${contact.id}`, {
      id: contact.id,
      name: contact.label,
      email: [contact.coordonnees.email],
      role: 'tester',
      photoUrl: `${this.apiUrl}/contact/picture/${contact.id}`,
      locale: 'fr-FR',
    });
  }

  getConversation(conversationId: string): Observable<Talk.Conversation> {
    return this.apiService.get(
      `${this.apiSegment}/conversation/${conversationId}`
    );
  }

  modifyConversation(
    conversationId: string,
    conversationData: Talk.ConversationData
  ): Observable<any> {
    return this.apiService.put(
      `${this.apiSegment}/conversation/${conversationId}`,
      conversationData
    );
  }

  deleteConversation(conversationId: string): Observable<any> {
    this.cleanParameters();

    return this.apiService.delete(
      `${this.apiSegment}/conversation/${conversationId}`
    );
  }

  joinConversation(
    conversationId: string,
    participantId: string,
    settings: Talk.ParticipationSettings
  ): Observable<any> {
    return this.apiService.put(
      `${this.apiSegment}/participant/${conversationId}/${participantId}`,
      settings
    );
  }

  modifyParticipantConversation(
    conversationId: string,
    participantId: string,
    settings: Talk.ParticipationSettings
  ): Observable<any> {
    return this.apiService.patch(
      `${this.apiSegment}/participant/${conversationId}/${participantId}`,
      settings
    );
  }

  deleteParticipantConversation(
    conversationId: string,
    participantId: string
  ): Observable<any> {
    return this.apiService.delete(
      `${this.apiSegment}/participant/${conversationId}/${participantId}`
    );
  }

  getUserConversationsCount(userId: number) {
    return this.apiService.get(
      `${this.apiSegment}/conversationsCount/${userId}`
    );
  }

  getUserConversations(userId: number) {
    return this.apiService.get(`${this.apiSegment}/conversations/${userId}`);
  }

  private cleanParameters() {
    this.participant = null;
    this.conversation = null;
    this.lastConversation = null;
    this.newConversation = null;
    this.other = null;
    this.baseConversationId = null;
  }
}
