import { Contact } from './contact.class';

export class Client extends Contact {
  constructor(options: {
    id?: number,
    provId?: number,
    nom?: string,
    prenom?: string,
    tel?: string,
    email?: string,
    role?: string
  }) {
    super(options);
    this.role = 'client';
  }
}
