import { Injectable } from '@angular/core';
import { AbstractEtlService } from '@core/etl/shared/abstract-etl.service';

// ------------------------------------ CLASSES
import { Event } from '../classes/event.class';
// ------------------------------------ SERVICES
import { EventEtlService } from './event-etl.service';

@Injectable({
  providedIn: 'root',
})
export class ToEventEtlService
  extends EventEtlService
  implements AbstractEtlService<Event, { data: any; model: any }, Event>
{
  constructor() {
    super();
  }

  extract() {
    if (this.context === 'initFromBdd') {
      this.orig = this.transformData<Event>(this.data.data, Event);

      return;
    }
    if (this.context === 'createFromForm') {
      this.orig = this.createFromForm(this.data.data);

      return;
    }
    super.extract();
  }

  transform() {
    super.transform();
    if (this.modelService) {
      this.modelService.provId = this.provId;
    }
    if (this.context === 'updateFromForm') {
      this.updateFromForm(this.data.data);
    }
  }

  createFromForm(data: any): Event {
    return new Event(data);
  }

  updateFromForm(data: any): void {
    const {
      chefId,
      contactsIds,
      tacheId,
      projetId,
      label,
      description,
      start,
      end,
      dayAll,
      color,
    } = data;

    this.model.update({
      chefId,
      contactsIds,
      tacheId,
      projetId,
      label,
      description,
      start,
      end,
      dayAll,
      color,
    });
  }
}
