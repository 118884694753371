import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

const colors = {
  green: {
    r: 93,
    v: 211,
    b: 124
  },
  blue: {
    r: 107,
    v: 147,
    b: 254
  },
  orange: {
    r: 224,
    v: 167,
    b: 77
  },
  red: {
    r: 233,
    v: 76,
    b: 80
  },
  purple: {
    r: 181,
    v: 104,
    b: 255
  },
};

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {

  @Input() mode = 'form';
  @Input() inputColor: string;
  @Output() outputColor = new EventEmitter<string>();

  colors: any[] = [];
  size = 30;
  height: number;
  width: number;
  r: number;
  cx: number;
  cy: number;

  selectedColor: string = null;


  constructor() { }

  ngOnInit(): void {
    this.height = this.size - 10;
    this.width = this.size - 10;
    this.cx = (this.size - 10) / 2;
    this.cy = (this.size - 10) / 2;
    this.r = Math.min(this.cx, this.cy);
    this.colors = Object.keys(colors).map((key: any) => {
      return Object.assign({
        name: key
      }, colors[key]);
    });
    this.selectedColor = this.inputColor ? this.inputColor : null;
  }

  calcFillColor(color: any) {
    return `rgb(${color.r}, ${color.v}, ${color.b})`;
  }

  selectColor(color: any) {
    if (this.mode === 'form') {
      this.selectedColor = color.name;
      this.outputColor.emit(this.selectedColor);
    }
  }

}
