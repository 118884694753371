import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { ToEventEtlService } from './etl/to-event-etl.service';
import { FromEventEtlService } from './etl/from-event-etl.service';

import { getUnixTime, startOfDay, endOfDay } from 'date-fns';

import { Event } from './classes/event.class';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  private apiSegment = 'event';

  lastView: any;
  currentDate: Date;
  current: Event;

  constructor(
    private apiService: ApiService,
    private stateService: StateService,
    private toEtl: ToEventEtlService,
    private fromEtl: FromEventEtlService
  ) {
    this.currentDate = new Date();
   }

  setLastView(params: any) {
    this.lastView = params;
  }

  count(params: any = {}) {
    if (params.today) {
      const today = new Date();
      params.dateb = getUnixTime(startOfDay(today));
      params.datee = getUnixTime(endOfDay(today));
    }
    params.scope = 'count';
    return this.apiService.count(this.apiSegment, params);
  }

  list(params: any = {}) {
    if (params.today) {
      const today = new Date();
      params.dateb = getUnixTime(startOfDay(today));
      params.datee = getUnixTime(endOfDay(today));
    }
    this.stateService.itemLoaded$.next(false);
    return this.apiService.get(`${this.apiSegment}/all`, params).pipe(
      map((elements: any[]) => elements.map((element: any) => this.createFromBdd(element))),
      tap(() => this.stateService.itemLoaded$.next(true))
    );
  }

  get(id: number, params = {}) {
    this.stateService.itemLoaded$.next(false);
    return this.apiService.get(`${this.apiSegment}/one/${id}`, params).pipe(
      map((element: any) => this.createFromBdd(element)),
      tap((element: Event) => {
        this.stateService.itemLoaded$.next(true);
        this.stateService.updateState({
          current: element
        });
      })
    );
  }

  create(data: any) {
    const obj = this.transformToBdd(this.createFromForm(data));
    return this.apiService.post(`${this.apiSegment}/create`, obj);
  }

  update(data: any, id?: number, params: any = {}) {
    if (!id) {
      id = data.id;
    }
    const obj = this.transformToBdd(this.createFromForm(data));
    return this.apiService.put(`${this.apiSegment}/one/${id}`, obj, params);
  }

  delete(id: number) {
    return this.apiService.delete(`${this.apiSegment}/one/${id}`);
  }

  createFromBdd(data: any): Event {
    const event = this.toEtl.etl('initFromBdd', {data});
    return event;
  }

  createFromForm(data: any): Event {
    const event = this.toEtl.etl('createFromForm', {data});
    return event;
  }

  updateFromForm(model: Event, data: any): Event {
    const event = this.toEtl.etl('updateFromForm', {model, data});
    return event;
  }

  transformToBdd(event: Event): any {
    const data = this.fromEtl.etl('transformToBdd', {model: event});
    return data;
  }

}
