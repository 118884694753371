import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';

import { TestdbComponent } from './testdb/testdb.component';


@NgModule({
  declarations: [TestdbComponent],
  imports: [
    CommonModule,
    CoreModule
  ],
  exports: [TestdbComponent]
})
export class TestdbModule { }
