import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'artizCallback',
  pure: false, // ????
})
export class ArtizCallbackPipe implements PipeTransform {
  transform(
    items: any[],
    callback: (item: any, callbackArgs?: any[]) => boolean,
    callbackArgs?: any[]
  ): any {
    if (!items || !callback) {
      return items;
    }

    return items.filter((item) => callback(item, callbackArgs));
  }
}
