import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})

export class DeviceService {

  constructor(private deviceService: DeviceDetectorService) {
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  isTablet(): boolean {
    return this.deviceService.isTablet();
  }

  isDesktopDevice(): boolean {
    return this.deviceService.isDesktop();
  }

  getDeviceInfo() {
    return this.deviceService.getDeviceInfo();
  }
}
