import { Injectable } from '@angular/core';
import { AbstractEtlService } from '@core/etl/shared/abstract-etl.service';

// ------------------------------------ CLASSES
import { Dossier } from '../classes/dossier.class';
// ------------------------------------ SERVICES
import { DossierEtlService } from './dossier-etl.service';

@Injectable({
  providedIn: 'root',
})
export class ToDossierEtlService
  extends DossierEtlService
  implements AbstractEtlService<Dossier, { data: any; model: any }, Dossier>
{
  constructor() {
    super();
  }

  extract() {
    if (this.context === 'initFromBdd') {
      this.orig = this.transformData<Dossier>(this.data.data, Dossier);

      return;
    }
    super.extract();
  }

  transform() {
    super.transform();

    if (this.modelService) {
      this.modelService.provId = this.provId;
    }
  }
}
