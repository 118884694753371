import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { GalleriaModule } from 'primeng/galleria';
import { SidebarModule } from 'primeng/sidebar';

import { AdresseGouvFieldComponent } from './components/adresse-gouv-field/adresse-gouv-field.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ContactFieldComponent } from './components/contact-field/contact-field.component';
import { ContactItemComponent } from './components/contact-item/contact-item.component';
import { ContactLinkComponent } from './components/contact-link/contact-link.component';
import { CoordonneesComponent } from './components/coordonnees/coordonnees.component';
import { DateFieldComponent } from './components/date-field/date-field.component';
import { DateHeureComponent } from './components/date-heure/date-heure.component';
import { DocumentFormComponent } from './components/document/document-form/document-form.component';
import { DocumentListComponent } from './components/document/document-list/document-list.component';
import { DocumentViewerComponent } from './components/document/document-viewer/document-viewer.component';
import { ImageGalleryComponent } from './components/document/image-gallery/image-gallery.component';
import { DossierCardComponent } from './components/dossier-card/dossier-card.component';
import { DossierStatusComponent } from './components/dossier-status/dossier-status.component';
import { FieldViewComponent } from './components/fields/field-view/field-view.component';
import { FormStepsComponent } from './components/form-steps/form-steps.component';
import { IconComponent } from './components/icon/icon.component';
import { LabelComponent } from './components/label/label.component';
import { ListeMenuItemsComponent } from './components/liste-menu-items/liste-menu-items.component';
import { ModalMenuComponent } from './components/modal-menu/modal-menu.component';
import { NotificationDotComponent } from './components/notification-dot/notification-dot.component';
import { NumberDisplayComponent } from './components/number-display/number-display.component';
import { ProfilComponent } from './components/profil/profil.component';
import { SearchFieldComponent } from './components/search/search-field/search-field.component';
import { SearchItemComponent } from './components/search/search-item/search-item.component';
import { SearchItemsComponent } from './components/search/search-items/search-items.component';
import { SvgDefComponent } from './components/svg-def/svg-def.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { TiersLinkComponent } from './components/tiers-link/tiers-link.component';
import { TitreComponent } from './components/titre/titre.component';
import { UserImageComponent } from './components/user-image/user-image.component';
import { UserLinkComponent } from './components/user-link/user-link.component';
import { ArtizCallbackPipe } from './pipes/artiz-callback.pipe';
import { ArtizLabelPipe } from './pipes/artiz-label.pipe';
import { ArtizTruncatePipe } from './pipes/artiz-truncate.pipe';

@NgModule({
  declarations: [
    AdresseGouvFieldComponent,
    ArtizCallbackPipe,
    ArtizLabelPipe,
    ArtizTruncatePipe,
    ColorPickerComponent,
    ContactFieldComponent,
    ContactItemComponent,
    ContactLinkComponent,
    CoordonneesComponent,
    DateFieldComponent,
    DateHeureComponent,
    DocumentFormComponent,
    DocumentListComponent,
    DocumentViewerComponent,
    DossierCardComponent,
    DossierStatusComponent,
    FieldViewComponent,
    FormStepsComponent,
    IconComponent,
    ImageGalleryComponent,
    LabelComponent,
    ListeMenuItemsComponent,
    ModalMenuComponent,
    NotificationDotComponent,
    NumberDisplayComponent,
    ProfilComponent,
    SearchFieldComponent,
    SearchItemComponent,
    SearchItemsComponent,
    SvgDefComponent,
    SvgIconComponent,
    TiersLinkComponent,
    TitreComponent,
    UserImageComponent,
    UserLinkComponent,
  ],
  imports: [
    AutoCompleteModule,
    CalendarModule,
    CardModule,
    ChartModule,
    CommonModule,
    ContextMenuModule,
    DialogModule,
    FormsModule,
    GalleriaModule,
    MatAutocompleteModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    RouterModule,
    SidebarModule,
  ],
  exports: [
    AdresseGouvFieldComponent,
    ArtizCallbackPipe,
    ArtizLabelPipe,
    ArtizTruncatePipe,
    ColorPickerComponent,
    ContactFieldComponent,
    ContactItemComponent,
    ContactLinkComponent,
    CoordonneesComponent,
    DateFieldComponent,
    DateHeureComponent,
    DocumentFormComponent,
    DocumentListComponent,
    DocumentViewerComponent,
    DossierCardComponent,
    DossierStatusComponent,
    FieldViewComponent,
    FormStepsComponent,
    IconComponent,
    ImageGalleryComponent,
    LabelComponent,
    ListeMenuItemsComponent,
    ModalMenuComponent,
    NotificationDotComponent,
    NumberDisplayComponent,
    ProfilComponent,
    SearchFieldComponent,
    SearchItemComponent,
    SearchItemsComponent,
    SvgDefComponent,
    SvgIconComponent,
    TiersLinkComponent,
    TitreComponent,
    TitreComponent,
    UserImageComponent,
    UserLinkComponent,
  ],
})
export class SharedModule {}
