import { IMenuItemConstants } from '@shared/interfaces/i-menu-item-constants';

export const items: IMenuItemConstants = {
  home: {
    icon: 'home',
    leftItem: 'menu',
    title: 'Accueil',
    content: 'dateHeure',
    menuIcon: 'home',
    menuTitle: 'Accueil',
    link: ['/'],
  },
  signout: {
    icon: 'logout',
    title: 'Se déconnecter',
    menuIcon: 'logout',
    menuTitle: 'Se déconnecter',
    link: 'signout',
  },
  connexion: {
    icon: 'sign-in',
    title: 'Connexion',
  },
  signup: {
    icon: 'add',
    title: 'Créer un compte',
    link: ['/auth/create'],
  },
  help: {
    icon: 'question',
    title: 'Aide',
    menuTitle: 'Aide',
    menuIcon: 'Aide',
    link: ['/'],
  },
  aproposlist: {
    icon: 'info',
    title: 'A propos',
    leftItem: 'menu',
    menuTitle: 'A propos',
    menuIcon: 'info',
    link: ['pages/apropos'],
  },
  bibliothequelist: {
    icon: 'info',
    title: 'Bibliotheque',
    leftItem: 'menu',
    menuTitle: 'Bibliotheque',
    menuIcon: 'info',
    link: ['pages/bibliotheque'],
  },
  menu: {
    icon: 'menu',
    title: 'Menu Principal',
  },
  agendaMenu: {
    icon: 'context-menu',
    title: 'Changer de vue',
    type: 'menu',
    model: 'agendaViewMenuItems',
    styleClass: 'agenda-header',
  },
  chatProfile: {
    icon: 'context-menu',
    title: 'Informations Chat',
    type: 'menu',
    model: 'chatProfileMenuItems',
    styleClass: 'chat-header',
  },
  chatGroup: {
    icon: 'context-menu',
    title: 'Informations groupe',
    type: 'menu',
    model: 'chatGroupMenuItems',
    styleClass: 'chat-header',
  },
  search: {
    icon: 'search',
    title: 'Rechercher',
  },
  back: {
    icon: 'arrow-left',
    title: 'Retour',
  },
  next: {
    icon: 'arrow-right',
    title: 'Suivant',
  },
  edit: {
    icon: 'edit',
    title: 'Modifier',
  },
  editMode: {
    icon: 'edit',
    title: 'ModifierMod',
  },
  check: {
    icon: 'check',
    title: 'check',
  },
  add: {
    icon: 'add',
    title: 'Ajouter',
  },
  addUser: {
    icon: 'add-user',
    title: 'Ajouter utilisateur',
  },
  addUserUp: {
    icon: 'add-user',
    title: 'Ajouter utilisateur UP',
  },
  addUp: {
    icon: 'add',
    title: 'Ajouter UP',
  },
  paperClip: {
    icon: 'paper-clip',
    item: 'UploadChat',
    title: 'upload chat',
  },
  upload: {
    icon: 'add',
    title: 'Upload',
  },
  cancel: {
    icon: 'undo',
    title: 'Annuler',
  },
  close: {
    icon: 'remove',
    title: 'Fermer',
  },
  save: {
    icon: 'check',
    title: 'Enregistrer',
    // action: 'save'
  },
  delete: {
    icon: 'trash',
    title: 'Supprimer',
    // action: 'delete'
  },
  deleteSelection: {
    icon: 'trash',
    title: 'Supprimer selection',
  },
  settings: {
    icon: 'settings',
    title: 'Parametres',
    menuIcon: 'settings',
    menuTitle: 'Parametres',
  },
  notifications: {
    icon: 'bell',
    title: 'notifications',
    menuIcon: 'bell',
    menuTitle: 'notifications',
  },
  signinauth: {
    icon: 'add',
    rightItem: 'signup',
    title: 'signin',
    link: ['/auth/'],
  },
  resetpasswordauth: {
    icon: 'add',
    leftItem: 'back',
    title: 'reset password',
    link: ['/auth/reset-password'],
  },
  contactadd: {
    icon: 'user',
    leftItem: 'back',
    rightItem: 'save',
    title: 'Nouveau contact',
    link: ['/contact/add'],
    color: 'linear-gradient(180deg, #D360FF, #8B49FF)',
  },
  contactedit: {
    icon: 'user',
    leftItem: 'back',
    rightItem: 'save',
    link: ['/contact'],
    color: 'linear-gradient(180deg, #D360FF, #8B49FF)',
  },
  contactview: {
    icon: 'user',
    leftItem: 'back',
    rightItem: 'edit',
    link: ['/contact/'],
    color: 'linear-gradient(180deg, #D360FF, #8B49FF)',
  },
  contactsearch: {
    icon: 'user',
    leftItem: 'back',
    link: ['/contact/search'],
    color: 'linear-gradient(180deg, #D360FF, #8B49FF)',
  },
  contactlist: {
    icon: 'user',
    title: 'Réseau',
    leftItem: 'menu',
    rightItem: 'search',
    bottomItem: 'add',
    menuIcon: 'team',
    menuTitle: 'Réseau',
    menuColor: 'linear-gradient(180deg, #D360FF, #8B49FF)',
    link: ['/contact'],
    color: 'purple',
  },
  dossierview: {
    icon: 'folders',
    leftItem: 'back',
    rightItem: 'edit',
    link: ['/dossier'],
    color: 'linear-gradient(180deg, #3BDBA7, #3BDB8D)',
  },
  dossierlist: {
    icon: 'folders',
    leftItem: 'menu',
    rightItem: 'search',
    bottomItem: 'add',
    title: 'Dossiers',
    menuIcon: 'folders',
    menuTitle: 'Dossiers',
    menuColor: 'linear-gradient(180deg, #3BDBA7, #3BDB8D)',
    link: ['/dossier'],
    color: 'linear-gradient(180deg, #3BDBA7, #3BDB8D)',
  },
  dossieredit: {
    icon: 'folders',
    leftItem: 'back',
    rightItem: 'delete',
    bottomItem: 'save',
    link: ['/dossier'],
    color: 'linear-gradient(180deg, #3BDBA7, #3BDB8D)',
  },
  dossieradd: {
    icon: 'folders',
    leftItem: 'back',
    rightItem: 'save',
    title: 'Nouveau dossier',
    link: ['/dossier'],
    color: 'linear-gradient(180deg, #3BDBA7, #3BDB8D)',
  },
  dossiersearch: {
    icon: 'folders',
    leftItem: 'back',
    link: ['/dossier/'],
    color: 'linear-gradient(180deg, #3BDBA7, #3BDB8D)',
  },
  gallerylist: {
    icon: 'folders',
    title: 'Documents',
    underTitle: ' Document',
    underTitleAlt: ' document séléctionnée',
    leftItem: 'back',
    rightItem: 'editMode',
    altRightItem: 'check',
    bottomItem: 'upload',
    uploadType: 'doc',
    altBottomItem: 'deleteSelection',
    color: 'linear-gradient(180deg, #3BDBA7, #3BDB8D)',
  },
  chatlist: {
    icon: 'messages',
    title: 'Messages',
    leftItem: 'menu',
    rightItem: 'paperClip',
    altRightItem: 'chatGroup',
    hideRightItem: true,
    rightItem2: 'addUser',
    hideRightItem2: false,
    link: '/chat',
    bottomItem: 'chatProfile',
    bottomUpItemDesktop: 'chatProfile',
    hideBottomItem: true,
    menuIcon: 'message',
    menuTitle: 'Messages',
    uploadType: 'chatUpload',
    menuColor: 'linear-gradient(180deg, #4B63FF, #6659FF)',
    color: 'linear-gradient(180deg, #4B63FF, #6659FF)',
  },
  chatview: {
    icon: 'messages',
    leftItem: 'back',
    rightItem: 'chatProfile',
    altRightItem: 'chatGroup',
    bottomItem: 'add',
    title: 'Messages',
    link: '/chat',
    uploadType: 'chatUpload',
    color: 'linear-gradient(180deg, #4B63FF, #6659FF)',
  },
  chatadd: {
    icon: 'messages',
    leftItem: 'back',
    rightItem: 'search',
    altRightItem: 'next',
    title: 'Nouveau groupe',
    link: ['/chat/add'],
    color: 'linear-gradient(180deg, #4B63FF, #6659FF)',
  },
  chatedit: {
    icon: 'messages',
    leftItem: 'close',
    rightItem: 'check',
    altRightItem: 'next',
    content: 'list',
    title: 'Modifier le groupe',
    link: ['/chat/edit'],
    color: 'linear-gradient(180deg, #4B63FF, #6659FF)',
  },
  chatsearch: {
    icon: 'messages',
    leftItem: 'back',
    link: ['/chat/contact/search'],
    color: 'linear-gradient(180deg, #D360FF, #8B49FF)',
  },
  uploadlist: {
    icon: 'messages',
    leftItem: 'close',
    rightItem: 'check',
    altRightItem: 'next',
    content: 'list',
    title: 'Joindre un fichier',
    underTitle: ' Document',
    underTitleAlt: ' document séléctionnée',
    link: ['/chat/upload'],
    color: 'linear-gradient(180deg, #4B63FF, #6659FF)',
  },
  reseau: {
    icon: 'team',
    title: 'Réseau',
    link: '/contact',
    color: 'linear-gradient(180deg, #D360FF, #8B49FF)',
  },
  agendaedit: {
    icon: 'calendar',
    leftItem: 'back',
    rightItem: 'delete',
    bottomItem: 'save',
    link: ['/agenda/edit'],
    color: 'linear-gradient(180deg, #FF805C, #F23C5B)',
  },
  agendaview: {
    icon: 'calendar',
    leftItem: 'back',
    link: ['/agenda/'],
    color: 'linear-gradient(180deg, #FF805C, #F23C5B)',
  },
  agendaadd: {
    icon: 'calendar',
    leftItem: 'back',
    rightItem: 'save',
    title: 'Nouvel événement',
    link: ['/agenda/add'],
    color: 'linear-gradient(180deg, #FF805C, #F23C5B)',
  },
  agendalist: {
    icon: 'calendar',
    title: 'Agenda',
    link: '/agenda',
    queryParams: { view: 'Month' },
    leftItem: 'menu',
    rightItem: 'agendaMenu',
    bottomItem: 'add',
    menuIcon: 'calendar',
    menuTitle: 'Agenda',
    menuColor: 'linear-gradient(180deg, #FF805C, #F23C5B)',
    color: 'linear-gradient(180deg, #FF805C, #F23C5B)',
  },
  tache: {
    icon: 'task-list',
    titleAdd: 'Nouvelle liste',
    title: 'Tâches',
    link: '/tache',
  },
  tacheadd: {
    icon: 'task-list',
    leftItem: 'back',
    rightItem: 'save',
    title: 'Nouvelle Liste de Tâche',
    link: ['/tache/add'],
    color: 'linear-gradient(180deg, #265769, #3D387D)',
  },
  tacheedit: {
    icon: 'task-list',
    leftItem: 'back',
    // rightItem: 'save',
    link: ['/tache'],
    color: 'linear-gradient(180deg, #265769, #3D387D)',
  },
  tacheview: {
    icon: 'task-list',
    leftItem: 'back',
    link: ['/tache'],
    color: 'linear-gradient(180deg, #265769, #3D387D)',
  },
  tachesearch: {
    icon: 'user',
    leftItem: 'back',
    link: ['/tache/search'],
    color: 'linear-gradient(180deg, #265769, #3D387D)',
  },
  tachelist: {
    icon: 'task-list',
    title: 'Tâches',
    leftItem: 'menu',
    rightItem: 'search',
    bottomItem: 'add',
    menuIcon: 'task-list',
    menuTitle: 'Tâches',
    menuColor: 'linear-gradient(180deg, #265769, #3D387D)',
    link: '/tache',
    color: 'linear-gradient(180deg, #265769, #3D387D)',
  },
  notes: {
    icon: 'bulb',
    title: 'Notes',
    link: '/dossier',
    color: 'linear-gradient(180deg, #F3C350, #F37E50)',
  },
  jobs: {
    icon: 'jobs',
    title: 'Jobs',
    link: '/dossier',
    color: 'linear-gradient(180deg, #1CDAEA, #1CEAC1)',
  },
  gestion: {
    icon: 'chair-director',
    title: 'Gestion',
    link: '/dossier',
    color: 'linear-gradient(180deg, #FF367B, #FF3640)',
  },
  userview: {
    icon: 'user',
    title: 'Profil',
    leftItem: 'back',
    content: 'user',
    color: 'purple',
  },
  aidesaides: {
    icon: 'money',
    title: 'Aides',
    link: '/aides',
    content: 'list',
    leftItem: 'menu',
    menuIcon: 'currency-euro',
    menuTitle: 'Aides',
    menuColor: 'linear-gradient(180deg, #e5302a, #721121)',
    color: 'linear-gradient(180deg, #e5302a, #721121)',
  },
};
