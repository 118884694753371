import { Injectable } from '@angular/core';
import { AbstractEtlService } from '@core/etl/shared/abstract-etl.service';

// ------------------------------------ CLASSES
import { Tache } from '../classes/tache.class';
// ------------------------------------ SERVICES
import { TacheEtlService } from './tache-etl.service';

@Injectable({
  providedIn: 'root',
})
export class ToTacheEtlService
  extends TacheEtlService
  implements AbstractEtlService<Tache, { data: any; model: any }, Tache>
{
  constructor() {
    super();
  }

  extract() {
    if (this.context === 'initFromBdd') {
      this.orig = this.transformData(this.data.data, Tache);

      return;
    }
    if (this.context === 'createFromForm') {
      this.orig = this.createFromForm(this.data.data);

      return;
    }
    super.extract();
  }

  transform() {
    super.transform();
    if (this.modelService) {
      this.modelService.provId = this.provId;
    }
    if (this.context === 'updateFromForm') {
      this.updateFromForm(this.data.data);
    }
  }

  createFromForm(data: any): Tache {
    const tache = new Tache(data);

    return tache;
  }

  updateFromForm(data: any): void {
    this.model.update(data);
    if (data.dossier) {
      this.model = data.dossier;
    }
  }
}
