import { Component, Input, OnInit } from '@angular/core';
import { Tiers } from '@contact/shared/classes/tiers.class';

@Component({
  selector: 'app-tiers-link',
  templateUrl: './tiers-link.component.html',
  styleUrls: ['./tiers-link.component.scss'],
})
export class TiersLinkComponent implements OnInit {
  @Input() tiers: Tiers;

  constructor() {}

  ngOnInit(): void {}
}
