import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-notification-dot',
  templateUrl: './notification-dot.component.html',
  styleUrls: ['./notification-dot.component.scss']
})
export class NotificationDotComponent implements OnInit {

  @Input() color: string;
  @Input() isActive: string;
  @Input() border: string;
  @Input() width: string;
  @Input() height: string;

  constructor() { }

  ngOnInit(): void {
  }

}
