import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/shared/auth.service';
import { environment } from '@environments/environment';
import { LocalStorageService } from '@offline/localstorage.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  take,
} from 'rxjs/operators';

import { StateService } from '../services/state.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private counter401 = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService,
    private stateService: StateService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.substring(0, environment.backApiUrl.length)
      !== environment.backApiUrl
    ) {
      return next.handle(request);
    }

    // on met le statut loading à TRUE
    this.stateService.isLoading$.next(true);

    // on vide les erreurs HTTP
    this.stateService.isHttpError$.next(null);

    let newRequest = request.clone();

    // Ajout du Content  Type pour les requêtes put et post
    /* newRequest = newRequest.clone({
      setHeaders: {
        'Content-Type':  'application/json',
      }
    }); */

    // Ajout du Token
    return this.localStorageService.getLocalToken().pipe(
      concatMap((token: string) => {
        if (token) {
          newRequest = this.addToken(newRequest, token);
        }

        return next.handle(newRequest).pipe(
          concatMap((response: HttpEvent<any>) => {
            if (response instanceof HttpResponse) {
              this.stateService.isLoading$.next(false);
              if (response.body.token) {
                this.refreshTokenSubject.next(response.body.token);

                return this.localStorageService
                  .setLocalToken(response.body.token)
                  .pipe(map(() => this.modifyResponseBody(response)));
              }

              return of(this.modifyResponseBody(response));
            }

            return of(response);
          }),
          catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              this.stateService.isLoading$.next(true);
              if (error.error === 'invalid token') {
                return this.handle401Error(newRequest, next);
              }
              console.error('unknown 401 error');
              this.stateService.isLoading$.next(false);
              this.authService
                .signout()
                .subscribe(() => this.router.navigate(['/auth']));

              return throwError(() => error);
            }

            return throwError(() => error);
          })
        );
      })
    );
  }

  private modifyResponseBody(response: HttpResponse<any>): HttpResponse<any> {
    return response.clone({ body: response.body.data });
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.http
        .get<any>(`${environment.backApiUrl}/auth/refreshToken`)
        .pipe(
          switchMap(() => {
            this.isRefreshing = false;
            const newRequest = this.addToken(
              request,
              this.refreshTokenSubject.value
            );

            return next.handle(newRequest).pipe(
              concatMap((response: HttpEvent<any>) => {
                if (response instanceof HttpResponse) {
                  return of(this.modifyResponseBody(response));
                }

                return of(response);
              })
            );
          }),
          catchError((error) => {
            console.error('error after 401 !', console.error('error', error));
            if (error instanceof HttpErrorResponse && error.status === 401) {
              this.authService
                .signout()
                .subscribe(() => this.router.navigate(['/auth']));
            } else if (
              error instanceof HttpErrorResponse
              && error.status === 404
            ) {
              this.router.navigate(['/']);
            }

            return throwError(() => error);
          })
        );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token != null),
      take(1),
      switchMap((jwt) =>
        next.handle(this.addToken(request, jwt)).pipe(
          concatMap((response: HttpEvent<any>) => {
            if (response instanceof HttpResponse) {
              return of(this.modifyResponseBody(response));
            }

            return of(response);
          })
        )
      ),
      catchError((error) => {
        console.error('error after 401 !');
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.authService
            .signout()
            .subscribe(() => this.router.navigate(['/auth']));
        }

        return throwError(() => error);
      })
    );
  }
}
