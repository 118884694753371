import { Base } from './base.class';

export class Reference extends Base {
  public static frontToBackProps = {
    ...Base.frontToBackProps,
    typeMenuiserieId: 'type_menuiserie_id',
    chauffageEmetteursId: 'chauffage_emetteurs_id',
    energieChauffageId: 'energie_chauffage_id',
    chauffageModeId: 'chauffage_mode_id',
    chauffageGenerateurId: 'chauffage_generateur_id',
    chauffageType2Id: 'chauffage_type_2_id',
    performanceId: 'performance_id',
    energieEcsId: 'energie_ecs_id',
    ecsModeId: 'ecs_mode_id',
    ecsGenerateurId: 'ecs_generateur_id',
    typeVentilationSimpl1Id: 'type_ventilation_simpl_1_id',
    typeVentilationSimpl2Id: 'type_ventilation_simpl_2_id',
    typeVentilationSimpl3Id: 'type_ventilation_simpl_3_id',
    typeElementId: 'type_element_id',
    typeComposantId: 'type_composant_id',
    composantTypeId: 'composant_type_id',
    composantCategorieId: 'composant_categorie_id',
    composantMateriauxId: 'composant_materiaux_id',
    composantType2Id: 'composant_type_2_id',
    porteTypeVitrageId: 'porte_type_vitrage_id',
    porteMateriauxId: 'porte_materiaux_id',
    fenetreMateriauxId: 'fenetre_materiaux_id',
    fenetrePontThermiqueId: 'fenetre_pont_thermique_id',
    fenetreTypeOuvertureId: 'fenetre_type_ouverture_id',
    portefenetreMateriauxId: 'portefenetre_materiaux_id',
    portefenetrePontThermiqueId: 'portefenetre_pont_thermique_id',
    portefenetreTypeOuvertureId: 'portefenetre_type_ouverture_id',
    portefenetreSousbassementId: 'portefenetre_sousbassement_id',
    deltaR: 'delta_r',
    typeVitrageId: 'type_vitrage_id',
    isolationGazId: 'isolation_gaz_id',
    isolationVitrageId: 'isolation_vitrage_id',
    lameAirId: 'lame_air_id',
    fermetureMateriauxId: 'fermeture_materiaux_id',
    fermetureTypeId: 'fermeture_type_id',
    fermetureDetailId: 'fermeture_detail_id',
    fermetureEpaisseurId: 'fermeture_epaisseur_id',

    chauffageSystemeId: 'chauffage_systeme_id',
    chauffageEnergieId: 'chauffage_energie_id',
    chauffageTypeId: 'chauffage_type_id',
    chauffageEmetteurId: 'chauffage_emetteur_id',
    chauffageEmetteur2Id: 'chauffage_emetteur_2_id',
    chauffageDateId: 'chauffage_date_id',

    ecsSystemeId: 'ecs_systeme_id',
    ecsTypeId: 'ecs_type_id',

    ventilationSystemeId: 'ventilation_systeme_id',
    ventilationEsId: 'ventilation_es_id',
    ventilationConsommationId: 'ventilation_consommation_id',
  };

  public static backToFrontProps = {
    ...Base.backToFrontProps,
    type_menuiserie_id: 'typeMenuiserieId',
    chauffage_emetteurs_id: 'chauffageEmetteursId',
    energie_chauffage_id: 'energieChauffageId',
    chauffage_mode_id: 'chauffageModeId',
    chauffage_generateur_id: 'chauffageGenerateurId',
    chauffage_type_2_id: 'chauffageType2Id',
    performance_id: 'performanceId',
    energie_ecs_id: 'energieEcsId',
    ecs_mode_id: 'ecsModeId',
    ecs_generateur_id: 'ecsGenerateurId',
    type_ventilation_simpl_1_id: 'typeVentilationSimpl1Id',
    type_ventilation_simpl_2_id: 'typeVentilationSimpl2Id',
    type_ventilation_simpl_3_id: 'typeVentilationSimpl3Id',
    type_element_id: 'typeElementId',
    type_composant_id: 'typeComposantId',
    composantTypeId: 'composant_type_id',
    composant_categorie_id: 'composantCategorieId',
    composant_materiaux_id: 'composantMateriauxId',
    composant_type_2_id: 'composantType2Id',
    porte_type_vitrage_id: 'porteTypeVitrageId',
    porte_materiaux_id: 'porteMateriauxId',
    fenetre_materiaux_id: 'fenetreMateriauxId',
    fenetre_pont_thermique_id: 'fenetrePontThermiqueId',
    fenetre_type_ouverture_id: 'fenetreTypeOuvertureId',
    portefenetre_materiaux_id: 'portefenetreMateriauxId',
    portefenetre_pont_thermique_id: 'portefenetrePontThermiqueId',
    portefenetre_type_ouverture_id: 'portefenetreTypeOuvertureId',
    portefenetre_sousbassement_id: 'portefenetre_sousbassement_id',
    type_vitrage_id: 'typeVitrageId',
    isolation_gaz_id: 'isolationGazId',
    lame_air_id: 'lameAirId',
    isolation_vitrage_id: 'isolationVitrageId',
    deltaR: 'delta_r',
    fermeture_materiaux_id: 'fermetureMateriauxId',
    fermeture_type_id: 'fermetureTypeId',
    fermeture_detail_id: 'fermetureDetailId',
    fermeture_epaisseur_id: 'fermetureEpaisseurId',

    chauffage_systeme_id: 'chauffageSystemeId',
    chauffage_energie_id: 'chauffageEnergieId',
    chauffage_type_id: 'chauffageTypeId',
    chauffage_emetteur_id: 'chauffageEmetteurId',
    chauffage_emetteur_2_id: 'chauffageEmetteur2Id',
    chauffage_date_id: 'chauffageDateId',

    ecs_systeme_id: 'ecsSystemeId',
    ecs_type_id: 'ecsTypeId',

    ventilation_systeme_id: 'ventilationSystemeId',
    ventilation_es_id: 'ventilationEsId',
    ventilation_consommation_id: 'ventilationConsommationId',
  };

  id?: number;

  label?: string;

  epaisseur?: string;

  u?: number;

  ug?: number;

  uw?: number;

  deltaR?: number;

  actif?: boolean;

  ordre: number;

  type?: number;

  typeMenuiserieId?: number;

  chauffageEmetteursId?: number;

  energieChauffageId?: number;

  chauffageModeId?: number;

  chauffageGenerateurId?: number;

  chauffageType2Id?: number;

  performanceId?: number;

  energieEcsId?: number;

  ecsModeId?: number;

  ecsGenerateurId?: number;

  typeVentilationSimpl1Id?: number;

  typeVentilationSimpl2Id?: number;

  typeVentilationSimpl3Id?: number;

  typeElementId?: number;

  typeComposantId?: number;

  composantTypeId?: number;

  composantCategorieId?: number;

  composantMateriauxId?: number;

  composantType2Id?: number;

  porteMateriauxId?: number;

  porteTypeVitrageId?: number;

  fenetreMateriauxId?: number;

  fenetrePontThermiqueId?: number;

  fenetreTypeOuvertureId?: number;

  portefenetreMateriauxId?: number;

  portefenetrePontThermiqueId?: number;

  portefenetreTypeOuvertureId?: number;

  portefenetreSousbassementId?: number;

  typeVitrageId?: number;

  isolationGazId?: number;

  lameAirId?: number;

  isolationVitrageId?: number;

  fermetureMateriauxId?: number;

  fermetureTypeId?: number;

  fermetureDetailId?: number;

  fermetureEpaisseurId?: number;

  chauffageSystemeId?: number;

  chauffageEnergieId?: number;

  chauffageTypeId?: number;

  chauffageEmetteurId?: number;

  chauffageEmetteur2Id?: number;

  chauffageDateId?: number;

  ecsSystemeId?: number;

  ecsTypeId?: number;

  ventilatiobnystemeId?: number;

  ventilationEsId?: number;

  ventilationConsommationId?: number;

  mur?: boolean;
  haut?: boolean;
  bas?: boolean;
  collectif?: boolean;
  individuel?: boolean;

  constructor(options: any) {
    Object.keys(Reference.frontToBackProps).forEach((key: string) => {
      if (
        options[key] === undefined &&
        options[Reference.frontToBackProps[key]] !== undefined
      ) {
        options[key] = options[Reference.frontToBackProps[key]];
      }
    });
    super(options);
    this.initFromArray(options, [
      'id',
      'label',
      'epaisseur',
      'u',
      'actif',
      'type',
      'deltaR',
      'typeMenuiserieId',
      'chauffageEmetteursId',
      'energieChauffageId',
      'chauffageModeId',
      'chauffageTypeId',
      'chauffageGenerateurId',
      'chauffageType2Id',
      'performanceId',
      'energieEcsId',
      'ecsModeId',
      'ecsGenerateurId',
      'typeVentilationSimpl1Id',
      'typeVentilationSimpl2Id',
      'typeVentilationSimpl3Id',
      'typeElementId',
      'typeComposantId',
      'composantTypeId',
      'composantCategorieId',
      'composantMateriauxId',
      'composantType2Id',
      'porteMateriauxId',
      'porteTypeVitrageId',
      'fenetreMateriauxId',
      'fenetrePontThermiqueId',
      'fenetreTypeOuvertureId',
      'portefenetreMateriauxId',
      'portefenetrePontThermiqueId',
      'portefenetreTypeOuvertureId',
      'portefenetreSousbassementId',
      'typeVitrageId',
      'isolationGazId',
      'lameAirId',
      'isolationVitrageId',
      'fermetureMateriauxId',
      'fermetureTypeId',
      '',
      'fermetureDetailId',
      'fermetureEpaisseurId',
      'chauffageSystemeId',
      'chauffageEnergieId',
      'chauffageTypeId',
      'chauffageEmetteurId',
      'chauffageEmetteur2Id',
      'chauffageDateId',
      'ecsSystemeId',
      'ecsTypeId',
      'ventilationSystemeId',
      'ventilationEsId',
      'ventilationConsommationId',
      'mur',
      'haut',
      'bas',
      'collectif',
      'individuel',
    ]);
  }
}
