import { Tools } from '../../shared/tools';

export class Base {
  static frontToBackProps = {
    provId: 'prov_id',
  };

  static backToFrontProps = {
    prov_id: 'provId',
  };

  id?: number;

  provId?: number;

  tags?: string[] = [];

  search?: string;

  constructor(options?: { id?: number; provId?: number; tags?: string[] }) {
    if (options) {
      Object.keys(Base.frontToBackProps).forEach((key: string) => {
        if (
          options[key] === undefined &&
          options[Base.frontToBackProps[key]] !== undefined
        ) {
          options[key] = options[Base.frontToBackProps[key]];
        }
      });
    }
    this.initFromArray(options, ['id', 'provId']);
    if (options && options.tags && options.tags.length) {
      this.tags = options.tags;
    }
  }

  protected initFromArray(options?, keys?: string[]): void {
    if (options && keys && keys.length) {
      keys.forEach((key: string) => {
        if (options[key] !== undefined) {
          this[key] = options[key];
        }
      });
    }
  }

  update(options): void {
    Object.keys(options).forEach((key: string) => {
      this[key] = options[key];
    });
  }

  isSame(other: this): boolean {
    return this.isSameObject(this, other);
  }

  isSameObject(obj1: unknown, obj2: unknown): boolean {
    return Tools.deepEqual(obj1, obj2);
  }
}
