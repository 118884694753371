import { Injectable } from '@angular/core';
import { AuthService } from '@auth/shared/auth.service';
import { items as headerItems } from '@core/constants/menu.constant';
import { IMenuItemConstant } from '@shared/interfaces/i-menu-item-constant';
import { User } from '@user/shared/classes/user.class';
import { UserService } from '@user/shared/user.service';
import { BehaviorSubject, Observable } from 'rxjs';

import { SelectedAndNumber } from '../classes/selectedAndNumber.class';
import { State } from '../classes/state.class';
import { UploadType } from '../classes/uploadType.class';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  static elements: ['dossier', 'contact', 'tache'];

  static displayedElements = {
    tache: 'Tâche',
  };

  isAuth$: Observable<boolean> = this.authService.isAuth$;

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  itemLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isHttpError$: BehaviorSubject<any> = new BehaviorSubject(null);

  isMenuActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isMenuActive = false;

  isPopupMessageUpper$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  user$: Observable<User> = this.userService.user$;

  user: User = null;

  state$: BehaviorSubject<State> = new BehaviorSubject(null);

  state: State = null;

  prevState: State = null;

  urlSegments$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  urlSegments: string[] = [];

  url$: BehaviorSubject<string> = new BehaviorSubject(null);

  url: string = null;

  params$: BehaviorSubject<any> = new BehaviorSubject(null);

  params: any = null;

  forms: { element: string; id?: number }[] = [];

  isSaveAllowed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  searchFilter$: BehaviorSubject<string> = new BehaviorSubject('');

  uploadRequest$: BehaviorSubject<UploadType> = new BehaviorSubject(null);

  editMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  deleteSelection$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  selectedAndNumber$: BehaviorSubject<SelectedAndNumber> = new BehaviorSubject(
    null
  );

  next$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  altLeft$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  headerMenuClick$: BehaviorSubject<any> = new BehaviorSubject(false);

  modalMenuClick$: BehaviorSubject<any> = new BehaviorSubject(false);

  changeItem$: BehaviorSubject<IMenuItemConstant> =
    new BehaviorSubject<IMenuItemConstant>(null);

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {}

  setState(url: string) {
    const segments = url.split('?')[0].split('/');

    const params = {};

    if (url.indexOf('?') !== -1 && url.indexOf('?') !== url.length) {
      url
        .split('?')[1]
        .split('&')
        .forEach((str: string) => {
          const p = str.split('=');

          params[p[0]] = p.length === 1 ? true : p[1];
        });
    }
    this.urlSegments = segments;
    this.urlSegments$.next(segments);
    this.url = segments.join('/');
    this.url$.next(segments.join('/'));
    this.params = Object.keys(params).length ? params : null;
    this.params$.next(this.params);

    const { page, element, id } = this.getPageElementId(segments);

    const newState = new State({ page, element, id });

    if (this.state) {
      this.prevState = new State({ ...this.state });
    }
    this.state = newState;
    this.state$.next(newState);
  }

  updateState(options: any) {
    const newState = new State({ ...this.state, ...options });

    if (this.state) {
      this.prevState = new State({ ...this.state });
    }
    this.state = newState;
    this.state$.next(newState);
  }

  getParentUrl(): string {
    if (
      this.prevState
      && this.state.element !== this.prevState.element
      && this.state.element === 'contact'
    ) {
      return 'back';
    }
    if (this.urlSegments[this.urlSegments.length - 1] === 'view') {
      return this.urlSegments.slice(0, this.urlSegments.length - 2).join('/');
    }
    if (
      this.state.page === 'view'
      && this.state.element !== this.urlSegments[1]
    ) {
      return this.urlSegments.slice(0, this.urlSegments.length - 2).join('/');
    }
    if (this.state.page === 'list' && this.state.element === 'chat') {
      return 'chat';
    }
    if (['add', 'edit'].includes(this.state.page) && this.forms.length > 1) {
      return this.getFormSegments(this.forms[this.forms.length - 2]).join('/');
    }
    if (this.state.page === 'list' && this.forms.length) {
      return this.getFormSegments(this.forms[this.forms.length - 1]).join('/');
    }
    if (
      this.state.page === 'edit'
      && ['agenda', 'tache'].includes(this.state.element)
    ) {
      return this.urlSegments.slice(0, 2).join('/');
    }

    return this.urlSegments.slice(0, this.urlSegments.length - 1).join('/');
  }

  getFormSegments(form: any): string[] {
    const segments: string[] = ['', form.element];

    if (form.id) {
      segments.push(form.id.toString());
      segments.push('edit');
    } else {
      segments.push('add');
    }

    return segments;
  }

  setAction(action: string) {
    this.state.action = action;
    this.state$.next(this.state);
  }

  setMenu(status: boolean) {
    this.isMenuActive = status;
    this.isMenuActive$.next(this.isMenuActive);
  }

  addForm(form: { element: string; id?: number }): void {
    this.forms.push(form);
  }

  removeForm(): void {
    this.forms.pop();
  }

  clearForms(): void {
    this.forms = [];
  }

  setSearchFilter(str: string): void {
    this.searchFilter$.next(str);
  }

  getElementName(): string {
    if (!this.state) {
      return;
    }
    if (!this.state.element) {
      return;
    }

    return StateService.displayedElements[this.state.element]
      ? StateService.displayedElements[this.state.element]
      : this.state.element;
  }

  getElementNameCapitalized(): string {
    const name = this.getElementName();

    if (!name) {
      return;
    }

    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  uploadRequest(type: UploadType) {
    this.uploadRequest$.next(type);
  }

  deleteSelection(del: boolean) {
    this.deleteSelection$.next(del);
  }

  setEditMode(editable: boolean) {
    this.editMode$.next(editable);
  }

  setNext(next: boolean) {
    this.next$.next(next);
  }

  setAltLeftItem(altLeft: boolean) {
    this.altLeft$.next(altLeft);
  }

  editHeader(value: any[], element: string[]) {
    const menuHeader = this.getMenuHeader();

    if (menuHeader) {
      value.forEach((val, index) => {
        menuHeader[element[index]] = val;
      });
      this.changeItem(menuHeader);
    }
  }

  changeItem(menuHeader: IMenuItemConstant): void {
    this.changeItem$.next(menuHeader);
  }

  headerMenuClick(menuItem: any) {
    this.headerMenuClick$.next(menuItem);
    this.headerMenuClick$.next(null); // avoid reading old click
  }

  modalMenuClick(item: any) {
    this.modalMenuClick$.next(item);
    this.modalMenuClick$.next(null);
  }

  selectedAndNumber(selectedAndNumber: SelectedAndNumber) {
    this.selectedAndNumber$.next(selectedAndNumber);
  }

  private getPageElementId(segments: string[]) {
    let page: string = null;

    let element: string = null;

    let id: number = null;

    if (segments.length && segments.length > 1) {
      if (!segments[1].length) {
        page = 'home';
      } else if (segments[1] === 'auth') {
        page = 'auth';
        if (segments.length === 2) {
          element = 'signin';
        } else {
          element = 'reset-password';
        }
      } else if (segments[1] === 'user') {
        element = 'user';
        if (segments[segments.length - 1] === 'edit') {
          page = 'edit';
        } else {
          page = 'view';
        }
      } else if (segments[1] === 'aides') {
        element = 'aides';
        page = 'aides';
      } else if (
        segments[1] === 'dossier'
        && segments[3]
        && segments[3] === 'etat-des-lieux'
      ) {
        element = 'etat-des-lieux';
        page = 'etat-des-lieux';
      } else {
        const lastSegment = segments[segments.length - 1];

        if (lastSegment === 'search') {
          page = 'search';
          element = segments[segments.length - 2];
        } else if (lastSegment === 'edit') {
          page = 'edit';
          element = segments[segments.length - 3];
          id = +segments[segments.length - 2];
        } else if (lastSegment === 'add') {
          page = 'add';
          element = segments[segments.length - 2];
        } else if (!isNaN(parseInt(lastSegment, 10))) {
          page = 'view';
          element = segments[segments.length - 2];
          id = +segments[segments.length - 1];
        } else if (lastSegment === 'view') {
          page = 'view';
          id = +segments[segments.length - 2];
          element = segments[segments.length - 3];
        } else {
          page = 'list';
          element = segments[segments.length - 1];
        }
        if (element === '') {
          element = segments[segments.length - 2];
          // throw(new Error('Missing element state'));
        }
      }
    }

    return { page, element, id };
  }

  getStateType(): string {
    if (this.state) {
      let type = this.state.element
        ? this.state.element + this.state.page
        : this.state.page;

      type = type.replace(/-/g, '');

      return type;
    }
  }

  getMenuHeader() {
    return headerItems[this.getStateType()];
  }
}
