import { DatePipe } from '@angular/common';

import { JSONObject } from './type-aliases';

type Constructor = new (...args: unknown[]) => unknown;

export class Tools {
  static isDebugMode = false;
  static isProfilerMode = true;

  /**
   * Extends the default console.log
   *
   * Note the extra () to call the original console.log
   *
   * StackOverflow: https://stackoverflow.com/a/29649777/5092978
   * @example
   * Tools.log("Foo", {bar: 1})();
   */
  static log(message?: unknown, ...optionalParams: unknown[]): () => void {
    // Put your extension code here

    if (Tools.isDebugMode) {
      optionalParams.unshift(message);
      optionalParams.unshift(console);

      return Function.prototype.bind.apply(console.log, optionalParams);
    }

    return (): void => {};
  }

  /**
   * Extends the default console.log
   *
   * Note the extra () to call the original console.log
   *
   * StackOverflow: https://stackoverflow.com/a/29649777/5092978
   * @example
   * Tools.log("Foo", {bar: 1})();
   */
  static logNico(message?: unknown, ...optionalParams: unknown[]): () => void {
    // Put your extension code here
    const isDebugMode = true;

    if (isDebugMode) {
      optionalParams.unshift(message);
      optionalParams.unshift(console);

      return Function.prototype.bind.apply(console.log, optionalParams);
    }

    return (): void => {};
  }

  /**
   * Extends the default console.group
   *
   * Note the extra () to call the original console.group
   *
   * StackOverflow: https://stackoverflow.com/a/29649777/5092978
   * @example
   * Tools.group("Foo")();
   */
  static group(message?: unknown, ...optionalParams: unknown[]): () => void {
    // Put your extension code here

    if (Tools.isDebugMode) {
      optionalParams.unshift(message);
      optionalParams.unshift(console);

      return Function.prototype.bind.apply(console.group, optionalParams);
    }

    return (): void => {};
  }

  /**
   * Extends the default console.group
   *
   * Note the extra () to call the original console.group
   *
   * StackOverflow: https://stackoverflow.com/a/29649777/5092978
   * @example
   * Tools.groupEnd("Foo")();
   */
  static groupEnd(message?: unknown, ...optionalParams: unknown[]): () => void {
    // Put your extension code here

    if (Tools.isDebugMode) {
      optionalParams.unshift(message);
      optionalParams.unshift(console);

      return Function.prototype.bind.apply(console.groupEnd, optionalParams);
    }

    return (): void => {};
  }

  /**
   * Extends the default console.log with time in header message
   *
   * Note the extra () to call the original console.log
   *
   * StackOverflow: https://stackoverflow.com/a/29649777/5092978
   * @example
   * Tools.profiler("Foo", {bar: 1})();
   */
  static profiler(message?: any, ...optionalParams: any[]): any {
    if (Tools.isProfilerMode) {
      optionalParams.unshift(message);
      optionalParams.unshift(Tools.timeHeader());
      optionalParams.unshift(console);

      return Function.prototype.bind.apply(console.log, optionalParams);
    }

    return (): void => {};
  }

  static timeHeader(): string {
    return `[ ${new DatePipe('fr-FR').transform(
      new Date(),
      'HH:mm:ss.SSS'
    )} ] -`;
  }

  static setDateFrom(source: Date, destination: Date): Date {
    destination.setFullYear(source.getFullYear());
    destination.setMonth(source.getMonth());
    destination.setDate(source.getDate());

    return destination;
  }

  static setTimeFrom(source: Date, destination: Date): Date {
    destination.setHours(source.getHours());
    destination.setMinutes(source.getMinutes());
    destination.setSeconds(source.getSeconds());

    return destination;
  }

  static capitalize(value: string): string {
    if (!value) return '';

    return value
      .split(' ')
      .map((val) => val.charAt(0).toUpperCase() + val.slice(1))
      .join(' ');
  }

  static initials(value: string): string {
    if (!value) return '';

    return value
      .split(' ')
      .map((val) => val.charAt(0).toUpperCase())
      .join('');
  }

  static isObjectEmpty(json: JSONObject): boolean {
    return !json || Object.keys(json).length === 0;
  }

  static deepEqual(a: unknown, b: unknown): boolean {
    if (a === b) return true;
    if (typeof a !== typeof b) return false;
    if (a === null || b === null) return false;
    if (Number.isNaN(a) && Number.isNaN(b)) return true;
    if (typeof a !== 'object' || typeof b !== 'object') return false;
    if ((a as Constructor).constructor !== (b as Constructor).constructor)
      return false;
    // if ((a as Constructor).prototype !== (b as Constructor).prototype) return false
    if (a instanceof Date) return a.getTime() === (b as Date).getTime();
    if (a instanceof Map || a instanceof Set)
      return Tools.deepEqual(
        Array.from(a.entries()),
        Array.from((b as typeof a).entries())
      );
    // && deepEqual(Object.entries(a),Object.entries(b)) // overkill
    const keys = Object.keys(a) as (keyof typeof a)[];

    if (keys.length !== Object.keys(b).length) return false;

    return keys.every((k) => Tools.deepEqual(a[k], b[k]));
  }
}
