import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { Dossier } from '@dossier/shared/classes/dossier.class';
import { map } from 'rxjs/operators';

import { Tache } from './classes/tache.class';
import { FromTacheEtlService } from './etl/from-tache-etl.service';
import { ToTacheEtlService } from './etl/to-tache-etl.service';

@Injectable({
  providedIn: 'root',
})
export class TacheService {
  private apiSegment = 'tache';

  current: Tache;

  original: Tache;

  constructor(
    private apiService: ApiService,
    private stateService: StateService,
    private toEtl: ToTacheEtlService,
    private fromEtl: FromTacheEtlService
  ) {}

  count(params: any = {}) {
    params.scope = 'count';

    return this.apiService.count(this.apiSegment, params);
  }

  list(params: any = {}) {
    return this.apiService
      .get(`${this.apiSegment}/all`, params)
      .pipe(
        map((elements: any[]) =>
          elements.map((element: any) => this.createFromBdd(element))
        )
      );
  }

  get(id: number, params = {}) {
    this.stateService.itemLoaded$.next(false);

    return this.apiService.get(`${this.apiSegment}/one/${id}`, params).pipe(
      map((element: any) => {
        this.current = this.createFromBdd(element);
        this.stateService.itemLoaded$.next(true);

        return this.current;
      })
    );
  }

  create(data: any) {
    // Tools.log('create data', data)();
    const obj = this.transformToBdd(this.createFromForm(data));

    if (this.current.dossier) {
      obj.dossier_ref = this.current.dossier.ref;
    }

    return this.apiService.post(`${this.apiSegment}/create`, obj);
  }

  update(data: any, id?: number, params: any = {}) {
    if (!id) {
      id = data.id;
    }
    const obj = this.transformToBdd(this.createFromForm(data));

    if (this.current.dossier) {
      obj.dossier_ref = this.current.dossier.ref;
    }

    // Tools.log('update data obj', data, obj)();
    return this.apiService.put(`${this.apiSegment}/one/${id}`, obj, params);
  }

  delete(id: number, params: any = {}) {
    return this.apiService.delete(`${this.apiSegment}/one/${id}`, params);
  }

  createFromBdd(data: any): Tache {
    if (data.dossier) {
      data.dossier = new Dossier(data.dossier);
    }
    const tache = this.toEtl.etl('initFromBdd', { data });

    return tache;
  }

  createFromForm(data: any): Tache {
    const tache = this.toEtl.etl('createFromForm', { data });

    return tache;
  }

  updateFromForm(model: Tache, data: any): Tache {
    const tache = this.toEtl.etl('updateFromForm', { model, data });

    return tache;
  }

  transformToBdd(tache: Tache): any {
    const data = this.fromEtl.etl('transformToBdd', { model: tache });

    return data;
  }
}
