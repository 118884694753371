import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
// import * as hammer from 'hammerjs';

@Injectable()


export class ImageHammerConfig extends HammerGestureConfig {
  overrides: any = {
    press: { time: 500 }
  };
}
