import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnonymGuard } from '@core/guards/anonym.guard';
import { AuthGuard } from '@core/guards/auth.guard';
import { NotFoundComponent } from '@pages/containers/not-found/not-found.component';

import { TestdbComponent } from './testdb/testdb/testdb.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    pathMatch: 'full',
    loadChildren: () => import('@home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'auth',
    canActivate: [AnonymGuard],
    loadChildren: () => import('@auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () => import('@user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'dossier',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@dossier/dossier.module').then((m) => m.DossierModule),
  },
  {
    path: 'contact',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'agenda',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@agenda/agenda.module').then((m) => m.AgendaModule),
  },
  {
    path: 'tache',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@tache/tache.module').then((m) => m.TacheModule),
  },
  {
    path: 'aides',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@aides/aides.module').then((m) => m.AidesModule),
  },
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'chat',
    canActivate: [AuthGuard],
    loadChildren: () => import('@chat/chat.module').then((m) => m.ChatModule),
  },
  // {
  //   path: 'resultat',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./resultat/resultat.module').then(m => m.ResultatModule)
  // },
  {
    path: 'testdb',
    component: TestdbComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
