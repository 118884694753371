import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = `${environment.backApiUrl}/`;

  constructor(private http: HttpClient) {}

  count(apiSegment: string, params: any = {}): Observable<number> {
    const httpParams = this.setParams(params);

    return this.http.get<number>(`${this.apiUrl}${apiSegment}/count`, {
      params: httpParams,
    });
  }

  get(
    apiSegment: string,
    params: any = {},
    responseType: string = null
  ): Observable<any> {
    const httpParams = this.setParams(params);

    const options: any = {
      params: httpParams,
    };

    if (responseType) {
      options.responseType = responseType;
    }

    return this.http.get(`${this.apiUrl}${apiSegment}`, options);
  }

  post(apiSegment: string, obj: any, params: any = {}): Observable<any> {
    const httpParams = this.setParams(params);

    return this.http.post(
      `${this.apiUrl}${apiSegment}`,
      obj,
      !httpParams ? {} : { params: httpParams }
    );
  }

  put(apiSegment: string, obj: any, params: any = {}): Observable<any> {
    const httpParams = this.setParams(params);

    return this.http.put(`${this.apiUrl}${apiSegment}`, obj, {
      params: httpParams,
    });
  }

  patch(apiSegment: string, obj: any, params: any = {}): Observable<any> {
    const httpParams = this.setParams(params);

    return this.http.patch(`${this.apiUrl}${apiSegment}`, obj, {
      params: httpParams,
    });
  }

  delete(apiSegment: string, params: any = {}): Observable<any> {
    const httpParams = this.setParams(params);

    return this.http.delete(
      `${this.apiUrl}${apiSegment}`,
      !httpParams ? {} : { params: httpParams }
    );
  }

  private setParams(params: any = {}): HttpParams | null {
    if (!params || !Object.keys(params).length) {
      return null;
    }
    let httpParams = new HttpParams();

    Object.keys(params).forEach((key: string) => {
      httpParams = httpParams.set(key, params[key]);
    });

    return httpParams;
  }
}
