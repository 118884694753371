import { Base } from '@core/classes/base.class';
import { Coordonnees } from '@core/classes/coordonnees.class';

export class Tiers extends Base {
  nom?: string;
  coordonnees?: Coordonnees;
  droits?: string;

  constructor(options: {
    id?: number,
    provId?: number,
    nom?: string,
    coordonnees?: Coordonnees,
    droits?: string,
    tags?: string[]
  }) {
    super(options);
    this.initFromArray(options, ['nom', 'coordonnees', 'droits']);
  }

  protected initFromArray(options?: any, keys?: string[]) {
    if (options && keys && keys.length) {
      keys.forEach((key: string) => {
        if (options[key] !== undefined) {
          if (key === 'coordonnees')  {
            this[key] = new Coordonnees(options[key]);
          } else  {
            this[key] = options[key];
          }
        }
      });
    }
  }
}
