import { Base } from '@core/classes/base.class';
import { Tiers } from './tiers.class';
import { Coordonnees } from '@core/classes/coordonnees.class';

export class Contact extends Base {
  nom?: string;
  prenom?: string;
  coordonnees?: Coordonnees;
  role?: string;
  tiers?: Tiers;
  droits?: string;
  label?: string;
  tags?: string[];
  hasImage?: boolean;
  image?: string | ArrayBuffer;

  constructor(options: {
    id?: number,
    provId?: number,
    nom?: string,
    prenom?: string,
    coordonnees?: Coordonnees,
    role?: string,
    tiers?: Tiers,
    droits?: string,
    tags?: string[],
    hasImage?: boolean,
    image?: string | ArrayBuffer
  }) {
    super(options);
    this.initFromArray(options, ['nom', 'prenom', 'coordonnees', 'role', 'tiers', 'droits', 'tags', 'hasImage']);
    this.setLabel();
    this.setSearch();
  }

  protected initFromArray(options?: any, keys?: string[]) {
    if (options && keys && keys.length) {
      keys.forEach((key: string) => {
        if (options[key] !== undefined) {
          if (key === 'coordonnees')  {
            if (options[key] === null) {
              this[key] = null;
            } else {
              this[key] = new Coordonnees(options[key]);
            }
          } else if (key === 'tiers')  {
            this[key] = new Tiers(options[key]);
          } else  {
            this[key] = options[key];
          }
        }
      });
    }
  }

  update(options): void {
    super.update(options);
    this.setLabel();
  }

  getPrenomNom(): string {
    const elts = [];
    if (this.prenom) {
      elts.push(this.prenom);
    }
    if (this.nom) {
      elts.push(this.nom);
    }
    return elts.join(' ');
  }


  setLabel(): void {
    this.label = this.getPrenomNom();
  }

  setSearch(): void {
    this.search = this.label;
    if (this.tiers && this.tiers.nom) {
      this.search += this.tiers.nom;
    }
  }


}
