import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dossier-status',
  templateUrl: './dossier-status.component.html',
  styleUrls: ['./dossier-status.component.scss']
})
export class DossierStatusComponent {

  @Input() status: string;
  @Input() color: string;
  @Input() intitule: string;

  constructor() { }


}
