export class UploadType {
  static IMAGE = ['gif', 'png', 'jpg', 'jpeg', 'bmp', 'webp'];
  static DOC = ['doc', 'docx', 'xls', 'xlsx', 'ppt',
   'pptx', 'pdf', 'zip', '7z', 'txt', 'md'];


  static image = 'image';
  static doc = 'doc';
  static chatUpload = 'chatUpload';

  types = ['image', 'doc', 'chatUpload'];
  selection: string;

  constructor(type: string) {
    if (this.types.includes(type)) {
      this.selection = type;
    }
  }
}
