import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  homeUrl = '/home';

  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToHomePage(event: Event): void {
    if (event) {
      event.stopPropagation();
    }

    this.router.navigateByUrl('/');
  }
}
