import { Component, Input, OnInit } from '@angular/core';
import { Dossier } from '@dossier/shared/classes/dossier.class';

@Component({
  selector: 'app-dossier-card',
  templateUrl: './dossier-card.component.html',
  styleUrls: ['./dossier-card.component.scss'],
})
export class DossierCardComponent implements OnInit {
  @Input() item: Dossier = null;

  element = 'dossier';

  link: string;

  label: string;

  constructor() {}

  ngOnInit(): void {
    this.link = `/${this.element}/${this.item.id}`;
  }
}
