import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Document } from '@core/classes/document.class';
import { DocumentService } from '@core/services/document.service';
import { StateService } from '@core/services/state.service';
import { ArtizLabelPipe } from '@shared/pipes/artiz-label.pipe';
import { map, mergeMap } from 'rxjs/operators';

import { DocumentListComponent } from '../document-list/document-list.component';
import { ImageGalleryComponent } from '../image-gallery/image-gallery.component';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
  providers: [ArtizLabelPipe],
})
export class DocumentViewerComponent implements OnInit {
  @Input() documents: Document[];

  @Input() editable = true;

  @ViewChild('imageGallery') imageGallery: ImageGalleryComponent;

  @ViewChild('documentList') documentList: DocumentListComponent;

  filters = ['Photos', 'Documents'];

  selectedFilter = 'Documents';

  // uploadType = 'image';
  uploadType = 'doc';

  images = new Array<Document>();

  docs = new Array<Document>();

  constructor(
    private documentService: DocumentService,
    private stateService: StateService,
    private artizLabel: ArtizLabelPipe
  ) {}

  ngOnInit(): void {
    if (this.documents) {
      this.loadImages();
    }
  }

  refresh(filter: string): void {
    if (this.selectedFilter !== filter) {
      this.selectedFilter = filter;
      if (filter === 'Photos') {
        this.uploadType = 'image';
      } else if (filter === 'Documents') {
        this.uploadType = 'doc';
      }
      this.stateService.editHeader([this.uploadType], ['uploadType']);
      this.stateService.setEditMode(false);
    }
  }

  loadImages(documents?: Document[]) {
    if (documents) {
      this.documents = documents;
    }
    this.docs.length = 0;
    this.images.length = 0;
    this.documents.map((d) => {
      if (
        d.extension === 'png'
        || d.extension === 'jpg'
        || d.extension === 'jpeg'
      ) {
        let file: File;

        this.documentService
          .get(d.erpId)
          .pipe(
            mergeMap((doc: File) => {
              file = doc;

              return this.documentService.reactiveReadFile(doc);
            }),
            map((content) => {
              d.content = content;
              d.file = file;
              if (this.images.filter((i) => i.erpId === d.erpId).length > 0) {
                return; // avoid add image twice
              }
              this.images.push(d);
              this.images.sort((a, b) => (a.erpId >= b.erpId ? -1 : 1));
              this.images = this.images.slice(); // force ngOnchanges
            })
          )
          .subscribe();
      } else {
        this.docs.push(d);

        return d;
      }
    });
  }

  downloadDocument(doc: Document) {
    return this.documentService.get(doc.erpId).subscribe((file) => {
      if (doc.extension === 'pdf') {
        this.documentService.openToAnotherWindow(file);
      } else {
        this.documentService.saveToDisk(file, doc.label);
      }
    });
  }

  public deleteSelectedDocument() {
    if (this.uploadType === 'doc') {
      this.documentList.deleteDocuments();
    } else if (this.uploadType === 'image') {
      this.imageGallery.deleteDocuments();
    }
  }

  setEditable(editable: boolean) {
    if (this.uploadType === 'doc') {
    } else if (this.uploadType === 'image') {
      this.imageGallery.setEditable(editable);
    }
  }
}
