import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Network } from '@ngx-pwa/offline';
import { LocalStorageService } from '@offline/localstorage.service';
import { User } from '@user/shared/classes/user.class';
import { UserService } from '@user/shared/user.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiSegment = 'auth';

  onlineChanges = this.network.onlineChanges;

  isOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  online = true;

  isAuth$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private userService: UserService,
    protected network: Network
  ) {
    this.onlineChanges.subscribe((online) => {
      this.online = online;
      this.isOnline$.next(online);
    });
  }

  // méthode appelée au chargement de l'appli si un token est enregistré
  checkAuth(): Observable<boolean> {
    if (this.isAuth$.value !== null) {
      return this.isAuth$; // bypass network check if already loggedIn
    }

    return this.localStorageService.getLocalToken().pipe(
      concatMap((token: string) => {
        if (!token) {
          return of(false);
        }

        return this.setCurrentUser();
      })
    );
  }

  // méthode appelée au chargement de l'appli si un token est enregistré
  signin(value: { username: string; password: string }): Observable<boolean> {
    return this.apiService
      .post(`${this.apiSegment}/signin`, value)
      .pipe(concatMap(() => this.setCurrentUser()));
  }

  signout() {
    return this.localStorageService.emptyLocalStorage().pipe(
      map(() => {
        this.isAuth$.next(false);
        this.userService.user = null;
        this.userService.user$.next(null);
      })
    );
  }

  resetPassword(value: { username: string }): Observable<boolean> {
    return this.localStorageService
      .emptyLocalStorage()
      .pipe(
        concatMap(() =>
          this.apiService.post(`${this.apiSegment}/forget-password`, value)
        )
      );
  }

  checkTempPassword(value: {
    login: string;
    hashPass: string;
  }): Observable<boolean> {
    return this.apiService
      .post(`${this.apiSegment}/check-temp-password`, value)
      .pipe(map((response: boolean) => response));
  }

  updatePassword(value: {
    password: string;
    login: string;
    hashPass: string;
  }): Observable<boolean> {
    return this.apiService
      .post(`${this.apiSegment}/reset-password`, value)
      .pipe(
        concatMap((response: boolean) => {
          if (!response) {
            return of(false);
          }

          return this.setCurrentUser();
        })
      );
  }

  // create(value: any): Observable<boolean> {
  //   return this.apiService.post(this.apiSegment + '/create', value).pipe(
  //     map((response: boolean) => {
  //       return response;
  //     })
  //   );
  // }

  getLocalToken(): Observable<any> {
    return this.localStorageService.getLocalToken();
  }

  private setCurrentUser(): Observable<boolean> {
    return this.userService.getCurrentUser().pipe(
      map((user: User) => {
        if (!user) {
          this.userService.user = null;
          this.userService.user$.next(null);

          return false;
        }
        this.userService.user = user;
        this.userService.user$.next(user);
        this.isAuth$.next(true);

        return true;
      })
    );
  }
}
