import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SelectedAndNumber } from '@core/classes/selectedAndNumber.class';
import { State } from '@core/classes/state.class';
import { UploadType } from '@core/classes/uploadType.class';
import { items as headerItems } from '@core/constants/menu.constant';
import { DeviceService } from '@core/services/device.service';
import { RightsService } from '@core/services/rights.service';
import { StateService } from '@core/services/state.service';
import { IMenuItemConstant } from '@shared/interfaces/i-menu-item-constant';
import { IMenuModel } from '@shared/interfaces/i-menu-model';
import { User } from '@user/shared/classes/user.class';
import { UserService } from '@user/shared/user.service';
import { MenuItem } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';

import { MenuItems } from '../menu/menuItems/menuItems';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnDestroy, OnInit {
  userSubscription: Subscription;

  user: User;

  stateSubscription: Subscription;

  state: State;

  isLoadedSubscription: Subscription;

  loaded = false;

  isSaveAllowedSubscription: Subscription;

  isSaveAllowed = false;

  editModeSubscription: Subscription;

  selectedAndNumberSubscription: Subscription;

  altLeftSubscription: Subscription;

  changeItemSubscription: Subscription;

  leftItems: MenuItem[] = [];

  rightItems: MenuItem[] = [];

  customRightItem: IMenuItemConstant = null;

  centerContent: string = null;

  altLeftItem = false;

  altRightItem = false;

  altBottomItem = false;

  countElement = 0;

  selected = false;

  underTitle: string;

  constructor(
    private router: Router,
    private stateService: StateService,
    private userService: UserService,
    private rightsService: RightsService,
    private deviceService: DeviceService,
    private menuItems: MenuItems,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.stateSubscription = this.stateService.state$.subscribe(
      (state: State) => {
        this.state = state;
        this.altRightItem = false;
        this.altBottomItem = false;
        this.altLeftItem = false;
        this.selected = false;
        this.countElement = 0;
        this.customRightItem = null;
        this.setItems();
      }
    );
    this.userSubscription = this.userService.user$.subscribe((user: User) => {
      this.user = user;
      this.setItems();
    });
    this.isLoadedSubscription = this.stateService.itemLoaded$.subscribe(
      (loaded: boolean) => {
        this.loaded = loaded;
        this.setItems();
      }
    );
    this.editModeSubscription = this.stateService.editMode$.subscribe(
      (editable: boolean) => {
        this.altRightItem = editable;
        this.altBottomItem = editable;
        this.setItems();
      }
    );
    this.altLeftSubscription = this.stateService.altLeft$.subscribe(
      (altLeft: boolean) => {
        this.altLeftItem = altLeft;
        this.setItems();
      }
    );
    this.changeItemSubscription = this.stateService.changeItem$.subscribe(
      (changeItem: IMenuItemConstant) => {
        this.customRightItem = changeItem;
        this.setItems();
      }
    );
    this.selectedAndNumberSubscription =
      this.stateService.selectedAndNumber$.subscribe(
        (selectedAndNumber: SelectedAndNumber) => {
          if (selectedAndNumber) {
            this.selected = selectedAndNumber.selected;
            this.countElement = selectedAndNumber.count;
            this.setItems();
          }
        }
      );
    this.isSaveAllowedSubscription = this.stateService.isSaveAllowed$.subscribe(
      (allowed: boolean) => {
        this.isSaveAllowed = allowed;
        this.disableSave();
      }
    );
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.stateSubscription.unsubscribe();
    this.isSaveAllowedSubscription.unsubscribe();
    this.isLoadedSubscription.unsubscribe();
    this.editModeSubscription.unsubscribe();
    this.selectedAndNumberSubscription.unsubscribe();
  }

  setItems(): void {
    if (this.state && this.loaded) {
      const type: string = this.stateService.getStateType();

      if (headerItems[type]) {
        let menuItem: IMenuItemConstant = headerItems[type];

        this.leftItems = [];
        this.rightItems = [];
        this.centerContent = null;

        if (menuItem.leftItem) {
          const tempLeftItem = !this.altLeftItem
            ? menuItem.leftItem
            : menuItem.altLeftItem;

          this.leftItems = this.getItems([tempLeftItem]);
        }

        if (menuItem.rightItem || menuItem.bottomItem) {
          menuItem = this.customRightItem ? this.customRightItem : menuItem;
          const rightItem = new Array<string>();

          if (menuItem.rightItem && !menuItem.hideRightItem) {
            rightItem.push(
              this.altRightItem ? menuItem.altRightItem : menuItem.rightItem
            );
          }

          if (menuItem.rightItem2 && !menuItem.hideRightItem2) {
            rightItem.push(menuItem.rightItem2);
          }

          if (menuItem.bottomItem && !menuItem.hideBottomItem) {
            if (menuItem.bottomUpItemDesktop) {
              rightItem.push(
                this.deviceService.isDesktopDevice()
                  || this.deviceService.isTablet()
                  ? menuItem.bottomUpItemDesktop
                  : menuItem.bottomItem
              );
            } else {
              rightItem.push(
                this.altBottomItem
                  ? menuItem.altBottomItem
                  : menuItem.bottomItem
              );
            }
          }

          if (
            this.selected
            && this.countElement === 0
            && menuItem.hideRightItem !== true
          ) {
            this.setRightItem([
              this.altRightItem ? menuItem.altRightItem : menuItem.rightItem,
            ]);
          } else {
            this.setRightItem(rightItem);
          }
        }

        if (menuItem.content) {
          this.centerContent = menuItem.content;
        } else {
          this.centerContent = this.state.page;
        }

        this.setUnderTitle(menuItem);
      } else if (this.state.page !== 'etat-des-lieux') {
        this.stateService.setState('/');
        throw new Error(`Unknown element error: ${type}`);
      }

      // ajout de l'info active sur l'item de menu sélectionné
      if (type === 'agendalist') {
        const view: string = !this.stateService.params
          ? null
          : this.stateService.params.view;

        this.menuItems.agendaViewMenuItems.forEach((item: MenuItem) => {
          item.styleClass = view === item.id ? 'active' : null;
        });
      }
    }
  }

  setRightItem(items: string[]): void {
    forkJoin(
      items.map((key: string) => this.rightsService.isAllowed(key))
    ).subscribe((rights: boolean[]) => {
      const keys: string[] = [];

      rights.forEach((r: boolean, index: number) => {
        if (r) {
          keys.push(items[index]);
        }
      });
      this.rightItems = this.getItems(keys);
      this.disableSave();
    });
  }

  setUnderTitle(menuItem: IMenuItemConstant): void {
    if (menuItem.underTitle) {
      if (!this.selected && this.countElement <= 1) {
        this.underTitle = `${this.countElement} ${menuItem.underTitle}`;
      } else if (!this.selected && this.countElement > 1) {
        this.underTitle = `${this.countElement} ${menuItem.underTitle}s`;
      } else if (this.selected && this.countElement > 0) {
        this.underTitle = `${this.countElement} ${menuItem.underTitleAlt}s`;
      } else {
        this.underTitle = `Aucun ${menuItem.underTitleAlt}`;
      }
    } else {
      this.underTitle = null;
    }
  }

  disableSave(): void {
    const index = this.rightItems.findIndex(
      (item: MenuItem) => item.id === 'save'
    );

    if (index !== -1) {
      this.rightItems[index].disabled = !this.isSaveAllowed;
    }
  }

  itemClick(menuItem: MenuItem): void {
    const key = menuItem.id;

    const item = headerItems[key];

    this.stateService.headerMenuClick(item);

    if (key === 'menu') {
      this.stateService.setMenu(true);

      return;
    }
    if (key === 'upload') {
      const { uploadType } = headerItems[this.state.element + this.state.page];

      this.stateService.uploadRequest(new UploadType(uploadType));

      return;
    }
    if (key === 'editMode') {
      this.stateService.setEditMode(true);

      return;
    }
    if (key === 'check') {
      this.stateService.setEditMode(false);

      return;
    }
    if (key === 'next') {
      this.stateService.setNext(true);

      return;
    }
    if (key === 'deleteSelection') {
      this.stateService.deleteSelection(true);

      return;
    }

    if (['save', 'delete'].includes(key)) {
      this.stateService.setAction(key);

      return;
    }

    let link: string[];

    if (item.link) {
      link = item.link;
    } else if (key === 'back' || key === 'close') {
      link = [this.stateService.getParentUrl()];
      if (link[0] === 'back' || key === 'close') {
        this.location.back();

        return;
      }
      if (this.stateService.forms.length) {
        this.stateService.removeForm();
      }
    } else if (
      ['edit', 'search', 'add', 'addUp', 'addUser', 'addUserUp'].includes(key)
    ) {
      if (key === 'addUp' || key === 'addUser' || key === 'addUserUp') {
        link = this.stateService.urlSegments.concat('add');
      } else {
        link = this.stateService.urlSegments.concat(key);
      }
    }
    if (
      key === 'search'
      && this.stateService.state.element === 'chat'
      && this.stateService.state.page === 'add'
    ) {
      link = ['chat', 'search'];
    } else if (
      key === 'back'
      && this.stateService.state.element === 'chat'
      && this.stateService.state.page === 'search'
    ) {
      link = ['chat', 'add'];
    }
    if (link) {
      this.router.navigate(link);
    }
  }

  private getActionItems(state: State): string[] {
    const keys: string[] = [];

    if (state) {
      if (state.page === 'list') {
        if (state.element === 'agenda') {
          keys.push('agendaMenu');
        } else {
          keys.push('search');
        }
        if (!this.stateService.forms.length) {
          keys.push('add');
        }
      } else if (state.page === 'view') {
        if (state.element !== 'user') {
          keys.push('edit');
        }
      } else if (state.page === 'edit') {
        if (
          !(
            state.element === 'tache'
            && this.stateService.params
            && ['PROCESS', 'CHANTIER'].includes(
              this.stateService.params.type_tache_code
            )
          )
        ) {
          keys.push('delete');
          keys.push('save');
        }
      } else if (state.page === 'add') {
        keys.push('save');
      }
    }

    return keys;
  }

  getMenuModel(model: string): Array<IMenuModel> {
    return this.menuItems.getMenuModel(model);
  }

  searchFieldName(): string {
    if (this.stateService.state.element === 'chat') {
      return 'contact';
    }

    return this.stateService.getElementName();
  }

  createLongToolClassName(item: MenuItem): string {
    if (item.id === 'signup' && item.label) {
      return `action-${item.id} longtool`;
    }

    return `action-${item.id}`;
  }

  private getItems(keys: string[]): MenuItem[] {
    return keys.map((key: string) => {
      const item = headerItems[key];

      return {
        id: key,
        label: item.title,
        icon: item.icon,
        type: item.type,
        model: item.model,
        styleClass: item.styleClass,
        command: ($event: MenuItem): void => this.itemClick($event),
      };
    });
  }
}
