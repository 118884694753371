import { Injectable } from '@angular/core';
import * as referencesFile from '@assets/references/references.json';
import { IReferenceLists } from '@shared/interfaces/i-reference-lists';
import { IReferenceOption } from '@shared/interfaces/i-reference-option';
import { IReferenceOptionFei } from '@shared/interfaces/i-reference-option-fei';

import { Reference } from '../classes/reference.class';

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  referenceLists: IReferenceLists = {};

  default = 'default';

  constructor() {
    this.init();
  }

  init(): void {
    Object.entries(referencesFile[this.default]).forEach(
      ([key, jsonReferenceOptions]: [string, Array<IReferenceOption>]) => {
        this.referenceLists[key] = [];
        jsonReferenceOptions.map((value) =>
          this.referenceLists[key].push(new Reference(value))
        );
      }
    );

    this.patch();
  }

  // @TODO : à supprimer quand le RefFei existera,
  // voir le fichier i-reference-lists.ts également
  private patch(): void {
    this.referenceLists.RefFei = [
      {
        id: 1,
        label: 'Pas obstrué',
        value: 0.7,
      },
      {
        id: 2,
        label: 'Légèrement obstrué',
        value: 0.5,
      },
      {
        id: 3,
        label: 'Très obstrué',
        value: 0.2,
      },
    ];
  }

  getItems(refType: string): Array<Reference> {
    return !this.referenceLists || !this.referenceLists[refType]
      ? []
      : (this.referenceLists[refType] as Array<Reference>);
  }

  getItem(reference: string, id: number): Reference {
    if (!this.referenceLists || !this.referenceLists[reference]) {
      return null;
    }
    const ref = (this.referenceLists[reference] as Array<Reference>).find(
      (r: Reference) => r.id === id
    );

    if (!ref) {
      return null;
    }

    return ref;
  }

  // @TODO : à supprimer quand le RefFei existera
  getItemsFei(): Array<IReferenceOptionFei> {
    return !this.referenceLists || !this.referenceLists.RefFei
      ? []
      : (this.referenceLists.RefFei as Array<IReferenceOptionFei>);
  }

  // @TODO : à supprimer quand le RefFei existera
  getItemFei(reference: string, id: number): IReferenceOptionFei {
    if (!this.referenceLists || !this.referenceLists[reference]) {
      return null;
    }
    const ref = (
      this.referenceLists[reference] as Array<IReferenceOptionFei>
    ).find((r: IReferenceOptionFei) => r.id === id);

    if (!ref) {
      return null;
    }

    return ref;
  }

  getLabel(reference: string, id: number): string {
    const ref = this.getItem(reference, id);

    if (!ref) {
      return null;
    }

    return ref.label;
  }

  getFermetureLists(): IReferenceLists {
    const refs: IReferenceLists = {};

    [
      'RefFermetureDelta',
      'RefFermetureMateriaux',
      'RefFermetureType',
      'RefFermetureEpaisseur',
    ].forEach((key: string) => {
      refs[key] = this.getItems(key);
    });

    return refs;
  }
}
