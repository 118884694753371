import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

const gouvAdresseUrl = 'https://api-adresse.data.gouv.fr/search/';

@Injectable({
  providedIn: 'root',
})
export class GouvAdresseService {
  constructor(private http: HttpClient) {}

  getAdresse(str: string) {
    const params = new HttpParams().set('q', str.replace(' ', '+'));

    return firstValueFrom(this.http.get(gouvAdresseUrl, { params })).then(
      (res: any) =>
        res.features.map((f: any) => {
          const { label, name, postcode, citycode, city, context } =
            f.properties;

          return {
            label,
            adresse: name,
            codePostal: postcode,
            codeInsee: citycode,
            ville: city,
            geometry: f.geometry,
            context,
          };
        })
    );
  }
}
