import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from '@chat/shared/chat.service';
import { StateService } from '@core/services/state.service';
import { DossierService } from '@dossier/shared/dossier.service';
import { Tools } from '@shared/tools';
import { TacheService } from '@tache/shared/tache.service';

@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.scss'],
})
export class SearchItemComponent implements OnInit {
  @Input() item: any;

  @Input() element: string;

  @Input() searchFilter: string;

  @Output() selected = new EventEmitter<any>();

  beforeSearchFilter: string = null;

  afterSearchFilter: string = null;

  link: string[];

  constructor(
    private router: Router,
    private dossierService: DossierService,
    private tacheService: TacheService,
    private stateService: StateService,
    private chatService: ChatService
  ) {}

  ngOnInit(): void {
    let str: string;

    switch (this.element) {
      case 'dossier':
      case 'tache':
        str = this.item.titre;
        break;
      case 'chat':
      case 'contact':
        str = this.item.label;
        break;
    }
    // const index = str.indexOf(this.searchFilter);
    // this.beforeSearchFilter = str.slice(0, index);
    // this.afterSearchFilter = str.slice(index + this.searchFilter.length);
    // this.link = ['/' + this.element + '/' + this.item.id];
  }

  onClick(): void {
    const { forms } = this.stateService;

    let segments: string[];

    switch (this.element) {
      case 'contact':
        if (
          forms
          && forms.length
          && forms[forms.length - 1].element === 'dossier'
        ) {
          Tools.log(this.stateService.state)();
          this.dossierService.current.setClient(this.item);
          segments = this.stateService.getFormSegments(forms[forms.length - 1]);
        } else {
          segments = this.stateService.urlSegments.slice();
          segments.pop();
          segments.push(this.item.id.toString());
        }
        break;
      case 'dossier':
        if (
          this.stateService.prevState.element === 'chat'
          && this.stateService.prevState.action
        ) {
          this.stateService.setAction(this.stateService.prevState.action);
          segments = ['', 'chat', String(this.item.id), 'upload'];
        } else if (
          forms
          && forms.length
          && forms[forms.length - 1].element === 'tache'
        ) {
          this.tacheService.current.dossier = this.item;
          if (this.tacheService.current.id) {
            segments = [
              '',
              'tache',
              this.tacheService.current.id.toString(),
              'edit',
            ];
          } else {
            segments = ['', 'tache', 'add'];
          }
        } else {
          segments = this.stateService.urlSegments.slice();
          segments.pop();
          segments.push(this.item.id.toString());
        }
        break;
      case 'chat':
        Tools.log(this.item)();
        this.selected.emit(this.item);
        this.chatService.initChatSession().subscribe(() => {
          this.chatService.startConversion([this.item]);
          this.chatService.goToConversation(true);
        });

        return;
        break;
    }
    this.stateService.setSearchFilter(null);
    this.router.navigate(segments);
  }
}
