import { Contact } from '@contact/shared/classes/contact.class';
import { Base } from '@core/classes/base.class';
import { Dossier } from '@dossier/shared/classes/dossier.class';
import {
  addMilliseconds,
  differenceInMilliseconds,
  isPast,
  isWithinInterval,
  startOfDay,
} from 'date-fns';

import * as taches from '../constants/taches.constants.json';

export class Tache extends Base {
  static taches = (taches as any).default;

  static frontToBackProps = {
    ...Base.frontToBackProps,
    projetCode: 'projet_code',
    dossierRef: 'dossier_ref',
    parentId: 'parent_id',
    start: 'date_start',
    end: 'date_end',
    projetStatusCode: 'projet_status_code',
    typeTacheCode: 'type_tache_code',
  };

  static backToFrontProps = {
    ...Base.backToFrontProps,
    projet_code: 'projetCode',
    dossier_ref: 'dossierRef',
    parent_id: 'parentId',
    date_start: 'start',
    data_end: 'end',
    projet_status_code: 'projetStatusCode',
    type_tache_code: 'typeTacheCode',
  };

  label?: string;

  description?: string;

  start?: Date;

  end?: Date;

  duration?: number;

  progress?: number;

  projetCode?: string;

  chef?: Contact;

  dossier?: Dossier;

  parentId?: number;

  artisans?: Contact[];

  todos?: number;

  done?: number;

  projetStatusCode?: string;

  typeTacheCode?: string;

  code?: string;

  def?: string;

  archive?: boolean;

  draft?: boolean;

  leaf?: boolean;

  children?: Tache[];

  rang?: number;

  constructor(options: {
    id?: number;
    provId?: number;
    label?: string;
    description?: string;
    start?: number | Date;
    end?: number | Date;
    duration?: number;
    progress?: number;
    projetCode?: string;
    dossier?: Dossier;
    parentId?: number;
    chef?: Contact;
    artisans?: Contact[];
    todos?: number;
    done?: number;
    projetStatusCode?: string;
    typeTacheCode?: string;
    code?: string;
    archive?: boolean;
    draft?: boolean;
    leaf?: boolean;
    children?: Tache[];
    rang?: number;
  }) {
    Object.keys(Tache.frontToBackProps).forEach((key: string) => {
      if (
        options[key] === undefined
        && options[Tache.frontToBackProps[key]] !== undefined
      ) {
        options[key] = options[Tache.frontToBackProps[key]];
      }
    });
    super(options);
    this.initFromArray(options, [
      'label',
      'description',
      'start',
      'end',
      'progress',
      'artisans',
      'projetCode',
      'chef',
      'dossier',
      'parentId',
      'todos',
      'done',
      'archive',
      'draft',
      'projetStatusCode',
      'typeTacheCode',
      'parentId',
      'code',
      'children',
      'leaf',
      'rang',
    ]);
    this.setDef();
  }

  protected initFromArray(options?: any, keys?: string[]) {
    if (options && keys && keys.length) {
      keys.forEach((key: string) => {
        if (options[key] !== undefined) {
          if (['start', 'end'].includes(key)) {
            if (options[key]) {
              if (typeof options[key] === 'number') {
                this[key] = new Date(options[key] * 1000);
              } else {
                this[key] = new Date(options[key]);
              }
            }
          } else if (key === 'children') {
            if (!options[key] || !(options[key] instanceof Array)) {
              this[key] = [];
            } else {
              this[key] = options[key].map((a: any) => new Tache(a));
            }
          } else if (key === 'artisans') {
            if (!options[key] || !(options[key] instanceof Array)) {
              this[key] = [];
            } else {
              this[key] = options[key].map((a: any) => new Contact(a));
            }
          } else if (key === 'chef') {
            this[key] = new Contact(options[key]);
          } else {
            this[key] = options[key];
          }
        }
      });
    }
  }

  update(data: any) {
    Object.keys(data).forEach((key: string) => {
      if (['start', 'end'].includes(key)) {
        if (data[key] === null || data[key] === '') {
          this[key] = null;
        } else if (typeof data[key] === 'number') {
          this[key] = new Date(data[key] * 1000);
        } else {
          this[key] = new Date(data[key]);
        }
      } else if (key === 'children') {
        if (!data[key] || !(data[key] instanceof Array)) {
          this[key] = [];
        } else {
          this[key] = data[key].map((a: any) => new Tache(a));
        }
      } else if (key === 'artisans') {
        if (!data[key] || !(data[key] instanceof Array)) {
          this[key] = [];
        } else {
          this[key] = data[key].map((a: any) => new Contact(a));
        }
      } else if (key === 'chef') {
        this[key] = new Contact(data[key]);
      } else {
        this[key] = data[key];
      }
    });
  }

  isToday(): boolean {
    return isWithinInterval(startOfDay(new Date()), {
      start: startOfDay(this.start),
      end: startOfDay(this.end),
    });
  }

  isLate(): boolean {
    if (this.isFinished()) {
      return false;
    }
    if (this.end) {
      return isPast(this.end);
    }
    if (this.start) {
      return isPast(this.start);
    }

    return false;
  }

  isFinished() {
    return this.progress === 100;
  }

  calcDuration() {
    if (!this.end || !this.start || this.end < this.start) {
      this.duration = 0;
    }
    this.duration = differenceInMilliseconds(this.end, this.start);
  }

  calcEnd() {
    if (!this.start) {
      this.end = null;
    }
    if (!this.duration) {
      this.end = new Date(this.start);
    }
    this.end = addMilliseconds(this.start, this.duration);
  }

  setDef() {
    this.def = Tache.taches.find((t: any) => {
      let founded = true;

      ['projetStatusCode', 'typeTacheCode', 'code'].forEach((key: string) => {
        if (founded && t[key] !== this[key]) {
          founded = false;
        }
      });

      return founded;
    });
  }
}
