import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@auth/shared/auth.service';
import { MyMessageService } from '@core/services/my-message.service';
import { Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { RightsService } from '../services/rights.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivate {
  constructor(
    private router: Router,
    private rightsService: RightsService,
    private authService: AuthService,
    private myMessageService: MyMessageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.checkAuth().pipe(
      concatMap((response: boolean) => {
        if (!response) {
          // if not auth
          this.router.navigate(['/auth']);

          return of(false);
        } // is auth, now check rights for url

        return this.rightsService.isRouteAllowed(state.url).pipe(
          map((isAllowed: boolean) => {
            if (!isAllowed) {
              this.router.navigate(['/auth']);
              this.myMessageService.add({
                severity: 'warn',
                summary: "Vous n'avez pas acces a cette page",
                detail: "Vous n'avez pas acces a cette page",
                sticky: false,
              });
            }

            return isAllowed;
          })
        );
      })
    );
  }
}
