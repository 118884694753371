import { Component, Input } from '@angular/core';
import { User } from '@user/shared/classes/user.class';

@Component({
  selector: 'app-user-link',
  templateUrl: './user-link.component.html',
  styleUrls: ['./user-link.component.scss'],
})
export class UserLinkComponent {
  @Input() user: User;

  baseUrl = '/user';
}
