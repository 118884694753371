import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as localeFr from '@localeFr';

/**
 * TODO: Still used as Pipe ????????????????????????????????
 * Renvoie la valeur associée dans le fichier @localeFr
 *   value | artizLabel
 * Example:
 *   {{ tel | artizLabel }}
 *   retourne: Téléphone
 */
@Pipe({ name: 'artizLabel' })
@Injectable({
  providedIn: 'root',
})
export class ArtizLabelPipe implements PipeTransform {
  fr = (localeFr as any).default;

  transform(value: string): string {
    if (this.fr[value]) {
      return this.fr[value];
    }

    return value;
  }
}
