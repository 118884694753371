import { Injectable } from '@angular/core';
import { AbstractEtlService } from '@core/etl/shared/abstract-etl.service';

// ------------------------------------ CLASSES
import { Contact } from '../classes/contact.class';
// ------------------------------------ SERVICES
import { ContactEtlService } from './contact-etl.service';

@Injectable({
  providedIn: 'root',
})
export class ToContactEtlService
  extends ContactEtlService
  implements AbstractEtlService<Contact, { data: any; model: any }, Contact>
{
  constructor() {
    super();
  }

  extract() {
    if (this.context === 'initFromBdd') {
      this.orig = this.transformData<Contact>(this.data.data, Contact);

      return;
    }
    if (this.context === 'createFromForm') {
      this.orig = this.createFromForm(this.data.data);

      return;
    }
    super.extract();
  }

  transform() {
    super.transform();
    if (this.modelService) {
      this.modelService.provId = this.provId;
    }
    if (this.context === 'updateFromForm') {
      this.updateFromForm(this.data.data);
    }
  }

  createFromForm(data: any): Contact {
    const c = new Contact(data);

    const obj: any = {
      coordonnees: {},
    };

    ['nom', 'prenom', 'tags'].forEach((key: string) => {
      obj[key] = data[key];
    });
    ['tel', 'email'].forEach((key: string) => {
      obj.coordonnees[key] = data.coordonnees[key];
    });
    obj.tiers = {
      nom: data.tags.includes('artisan') ? data.tiers.nom : c.label,
      tags: data.tags,
      coordonnees: {},
    };
    ['adresse', 'codePostal', 'ville', 'pays'].forEach((key: string) => {
      obj.tiers.coordonnees[key] = data.coordonnees[key];
    });

    return new Contact(obj);
  }

  updateFromForm(data: any): void {
    const { nom, prenom } = data;

    this.model.update({ nom, prenom });
    const { tel, email } = data.coordonnees;

    this.model.coordonnees.update({ tel, email });
    const { adresse, codePostal, ville, pays } = data.coordonnees;

    this.model.tiers.coordonnees.update({ adresse, codePostal, ville, pays });
    if (
      this.model.tags
      && this.model.tags.length
      && this.model.tags.includes('client')
    ) {
      this.model.tiers.update({
        nom: this.model.label,
      });
    }
  }
}
