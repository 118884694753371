import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-number-display',
  templateUrl: './number-display.component.html',
  styleUrls: ['./number-display.component.scss']
})
export class NumberDisplayComponent implements OnInit {


  @Input() value: number;
  @Input() currency: boolean;
  @Input() percent: boolean;
  @Input() prefix: boolean;
  @Input() suffix: boolean;
  @Input() zeroIfEmpty: boolean;
  @Input() format: string;

  constructor() { }

  ngOnInit(): void {
    if (this.percent && this.value < 1) {
      this.value *= 100;
    }
    if (this.zeroIfEmpty && !this.value) {
      this.value = 0;
    }
    if (!this.format) {
      if (this.currency) {
        this.format = '1.2-2';
      } else {
        this.format = '1.0-0';
      }
    }
  }
}
