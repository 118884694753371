import { Component, Input } from '@angular/core';
import { Contact } from '@contact/shared/classes/contact.class';

@Component({
  selector: 'app-contact-link',
  templateUrl: './contact-link.component.html',
  styleUrls: ['./contact-link.component.scss'],
})
export class ContactLinkComponent {
  @Input() contact: Contact;

  baseUrl = './contact/';
}
